import React from "react";
import {observer} from "mobx-react";
import PropTypes from "prop-types";
import {Input, Button, Icon} from "semantic-ui-react";

@observer
export default class TextEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text : this.props.text || '',
            isEdit : false };
    }

    editClicked(e) {
        this.setState({isEdit: true});
    }

    submitClicked() {
        this.setState({isEdit: false});
        this.props.textChangedCallback(this.state.text);
    }

    cancelPressed() {
        this.setState({text: this.props.text, isEdit: false});
    }

    editText({ target }) {
        this.setState({text: target.value});
    }

    render() {
        const isEdit = this.state.isEdit;
        return (
            <div>
            { isEdit === true ? <Input type ='text' placeholder='...' value={this.state.text} onChange={this.editText.bind(this)} action  >
                                        <input />
                                        <Button.Group attached='left'>
                                            <Button positive icon onClick={this.submitClicked.bind(this)} style={{borderRadius: "0px"}}><Icon name='checkmark'/></Button>
                                            <Button negative icon onClick={this.cancelPressed.bind(this)}><Icon name='cancel'/></Button>
                                        </Button.Group>
                                </Input>
                : <p style={{"textDecoration": "underline"}} onClick={ this.editClicked.bind(this)}> { this.state.text === '' ? '+' : this.state.text   } </p>
            }
            </div>
        );
    }
}

TextEdit.propTypes = {
    text: PropTypes.string,
    textChangedCallback: PropTypes.func.isRequired
};

TextEdit.defaultPropTypes = {
    text: ""
};
