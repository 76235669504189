import {action, computed, observable} from 'mobx';
import config from "../../config/main.config";
import {
    getTokenFromLocalStorage,
} from "../helper/util";
import history from "../helper/browserHistory"

class ScaleStore {
    @observable scales = [];

    @observable loading = false;
    @observable error = false;

    @action fetchScales() {
        this.loading = true;
        return fetch(config.BASE_URL + "user/scale", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        this.scales = json.scales;
                    });
                } else {
                    this.error = true;
                    this.customers = [];
                }
            })
            .catch(
                error => {
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }

    @action updateScale(id, username, name, producerNr, tbSoftNr, isActive ) {
        this.loading = true;
        return fetch(config.BASE_URL + "user/scale", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                id, username, name, producerNr, tbSoftNr, isActive
            })
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        this.fetchScales();
                    });
                } else {
                    this.error = true;
                }
            })
            .catch(
                error => {
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }

    @action addScale(password, username, name, producerNr, tbSoftNr, isActive) {
        this.loading = true;
        return fetch(config.BASE_URL + "user/scale", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({password, name, username, producerNr, tbSoftNr, isActive})
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.fetchScales();
                });
            } else {
                this.error = true;
            }
        })
            .catch(
                error => {
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }

    @action updateScalePassword(id, password) {
        console.log("update scale")
        this.loading = true;
        return fetch(config.BASE_URL + "user/password/scale", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                id: id,
                password: password
            })
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                       this.fetchScales();
                    });
                } else {
                    this.error = true;
                }
            })
            .catch(
                error => {
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }

    @action deleteScale(id) {
        this.loading = true;
        return fetch(config.BASE_URL + "user/scale", {
            method: "DELETE",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        this.fetchScales();
                    });
                } else {
                    this.error = true;
                }
            })
            .catch(
                error => {
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }

}

export default ScaleStore;