import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Menu, Segment, Icon} from "semantic-ui-react";
import { isAdmin, isFarmer, getUserFromLocalStorage, removeUserFromStorage } from "../helper/util";
import userStore from "../stores/userStore";
import history from "../helper/browserHistory";
// import {checkRole} from "../helper/rolehelper";
import {withTranslation} from "react-i18next";

@inject("stores")
@withTranslation()
@observer
export default class NavigationBar extends React.Component {
    constructor(props) {
        super(props);
        this.menuItems = [];
        this.state = {
            active: 'login',
            internal: 'login'
        }
        this.userStore = props.stores.userStore;
    }

    logoutUser() {
        removeUserFromStorage();
        history.push("/");
        this.setState({});
    }

    setMenuItems() {
        const user = getUserFromLocalStorage();
        const {i18n} = this.props;
        this.menuItems  = [];
        let active = "login"
        if (window.location.href.search("/delivery") !== -1) {
            active = "delivery"
        }
        if (window.location.href.search("/login") !== -1) {
            active = "login"
        }
        if (window.location.href.search("/producer") !== -1) {
            active = "producer"
        }
        if (window.location.href.search("/customer") !== -1) {
            active = "customer"
        }
        if (window.location.href.search("/settings") !== -1) {
            active = "settings"
        }
        if (window.location.href.search("/scale") !== -1) {
            active = "scale"
        }
        if (window.location.href.search("/admin") !== -1) {
            active = "admin"
        }

        if (user === null) {
            this.menuItems.push(
                <div style={{ position:'absolute', bottom:'30px', width:'auto'}}>
                    <Menu.Item
                        active={active === 'login'}
                        onClick={() => {
                            history.push("/login");
                            this.setState({})
                        }}
                        icon={"sign in"}
                        content={i18n.t("menu.login")}
                        key="menu-item-login">
                    </Menu.Item>
                </div>);
            return;
        }


        if (user.role === "admin" || user.role === "producer" || user.role === "customer") {
            this.menuItems.push(
                <Menu.Item
                    active={active === 'delivery'}
                    onClick={() => {
                        history.push("/delivery");
                        this.setState({});
                    }}
                    key="menu-item-delivery">
                    {i18n.t("menu.delivery")}
                </Menu.Item>);
        }

        if (user.role === "admin" || user.role === "producer") {
            this.menuItems.push(
                <Menu.Item
                    active={active === 'customer'}
                    onClick={() => {
                        history.push("/customer");
                        this.setState({});
                    }}
                    key="menu-item-customer">
                    {i18n.t("menu.customer")}
                </Menu.Item>);
        }

        if (user.role === "admin" ) {
            this.menuItems.push(
                <Menu.Item
                    active={active === 'producer'}
                    onClick={() => {
                        history.push("/producer");
                        this.setState({});
                    }}
                    key="menu-item-producer">
                    {i18n.t("menu.producer")}
                </Menu.Item>);
        }

        if (user.role === "admin") {
            this.menuItems.push(
                <Menu.Item
                    active={active === 'scale'}
                    onClick={() => {
                        history.push("/scale");
                        this.setState({});
                    }}
                    key="menu-item-scale">
                    {i18n.t("menu.scale")}
                </Menu.Item>);
        }


        if (user.role === "admin") {
            this.menuItems.push(
                <Menu.Item
                    active={active === 'admin'}
                    onClick={() => {
                        history.push("/admin");
                        this.setState({});
                    }}
                    key="menu-item-admin">
                    {i18n.t("menu.admin")}
                </Menu.Item>);
        }

        if (user.role === "customer") {
            this.menuItems.push(
                <Menu.Item
                    active={active === 'settings'}
                    onClick={() => {
                        history.push("/settings");
                        this.setState({});
                    }}
                    key="menu-item-settings">
                    {i18n.t("menu.settings")}
                </Menu.Item>);
        }

        this.menuItems.push(
                <Menu.Item
                    active={ active === 'logout'}
                    onClick={() => {
                        this.logoutUser();
                    }}
                    icon={"sign out"}
                    content={i18n.t("menu.logout")}
                    key="menu-item-login">
                </Menu.Item>);
    }

    render() {
        const {i18n} = this.props;
        // for rerender
        const usr = this.userStore.user;
        const user = getUserFromLocalStorage();
        this.setMenuItems();
        return (
            <Menu key="menu_main" inverted fluid style={{ borderRadius: "0", paddingLeft: "50px", backgroundColor: "#6F249B", textColor: "#FFFFFF"}}>
                { this.menuItems }
                <Menu.Item position={'right'} style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "20px"}}>
                    <h3 style={{paddingBottom: "10px"}}> { user === null ? null : user.hasOwnProperty("prename") === true ? user.prename + " " + user.surname + " - " : ''} { i18n.t('menu.title') }</h3>
                </Menu.Item>
            </Menu>
    );
    }
}