import React from "react";
import {
    Button,
    Header,
    Loader,
    Dimmer,
    Dropdown,
    Form,
    Grid,
    List,
    Modal,
    Table,
    TableHeader,
    Input,
    Pagination, GridColumn
} from "semantic-ui-react";

import config from "../../config/main.config";
import {inject, observer} from "mobx-react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {withTranslation} from "react-i18next";
import dayjs from "dayjs";
import CustomerSelector from "../components/CustomerSelector"
import DeliveryList from "../components/DeliveryList"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import UploadPdf from "../components/UploadPdf";

@inject("stores")
@withTranslation()
@observer
export default class DeliveryPage extends React.Component {
     constructor(props) {
        super(props);
        this.userStore = props.stores.userStore;
        this.user = this.userStore.loadUser();
        this.deliveryStore = props.stores.deliveryStore;
        let tbSoftNr = 0;
        let producerNr = 0;
        let customerNr = 0;
        if (this.user.role === "admin") {
            // producerNr wird ausgewählt über Dropdown
            // customerNr wird ausgewählt über Sidebar ausgewählt
        } else if (this.user.role === "producer") {
            producerNr = this.user.producerNr;
            tbSoftNr = this.user.tbSoftNr;
            this.deliveryStore.setSelectedTbSoftProducer(tbSoftNr, producerNr);
            // customerNr wird ausgewählt über Sidebar ausgewählt
        } else if (this.user.role === "customer") {
            producerNr = this.user.producerNr;
            customerNr = this.user.customerNr;
            tbSoftNr = this.user.tbSoftNr;
            this.deliveryStore.setSelectedTbSoftProducer(tbSoftNr, producerNr);
            this.deliveryStore.setSelectedCustomerNr(customerNr);
        }

        this.state = {
            year: dayjs().year(),
            month: dayjs().month()+1,
            search: "",
            tbSoftNr: tbSoftNr,
            producerNr: producerNr,
            customerNr: customerNr,
            selectedProducer: "",
            isAddModalOpen: false,
            isAddExcelModalOpen: false,
            isDeleteModalOpen: false,
            addYear: 0,
            addSerial: "",
            addBrushName: "",
            addTransmission: "",
            addMotorPcbSerial: "",
            addMotor: "",
            addEmployee: "",
            addAnimal: "cow",
            isEdit: false,
            editId: 0,
            yearFromSerial: "",
            selectedFile: null,
            activePage: 1,
            noItems: 25,
        };
        this.brushToDelete = { serial: 0 };
        this.yearOptions = [];

        this.deliveryStore.setSelectedMonthYear(this.state.month, this.state.year);

        for (let i = dayjs().year() ; i >= config.START_YEAR; i--) {
            this.yearOptions.push({
                key: i,
                text: i.toString(),
                value: i
            })
        }
         const deliveryPerPage = props.i18n.t("delivery.deliveryPerPage");
         this.noItemsOptions = [
             {
                 key: 0,
                 text: "10 " + deliveryPerPage,
                 value: 10
            },
             {
                 key: 1,
                 text: "25 " + deliveryPerPage,
                 value: 25
             },
             {
                 key: 2,
                 text: "50 " + deliveryPerPage,
                 value: 50
             },
             {
                 key: 3,
                 text: "100 " + deliveryPerPage,
                 value: 100
             }];

     }

    componentDidMount() {
       if (this.user.role === "admin") {
           this.userStore.fetchProducers();
       }
       if (this.user.role === "producer") {
           this.userStore.fetchCustomers(this.state.tbSoftNr, this.state.producerNr);
       }
       if (this.user.role === "customer") {
           this.deliveryStore.fetchDelivery();
       }
    }

    searchChanged(e) {
       this.setState({search: e.target.value});
    }

    yearChanged(e, {value}) {
        this.setState({ year: value });
        this.deliveryStore.setSelectedMonthYear(this.state.month, value);
        this.deliveryStore.fetchDelivery()

    }

     askForDelete(brush) {
         this.setState({isDeleteModalOpen :true})
         this.brushToDelete = brush;
    }


    monthChanged(month) {
        this.setState({ month: month });
        this.deliveryStore.setSelectedMonthYear(month, this.state.year);
        this.deliveryStore.fetchDelivery()
    }

    producerSelected(e, {value}) {
         let producer = this.userStore.producers.find(prod => prod.id === value);
         this.userStore.fetchCustomers(producer.tbSoftNr, producer.producerNr);
         this.deliveryStore.setSelectedTbSoftProducer(producer.tbSoftNr, producer.producerNr);
         this.setState({ selectedProducer: value });
    }

    render() {
        const {i18n} = this.props;
        let producerOptions = [];
        if (this.userStore.producers.length > 0) {
            producerOptions = this.userStore.producers.map( producer =>
            { return (
                {   key: producer.id,
                    text: producer.tbSoftNr + " | " + producer.producerNr + ": " + producer.companyName + " | " + producer.prename + " " + producer.surname,
                    value: producer.id }
            )
            });
        }


        return (
            <div>
               <Grid>
                   { this.user.role === "admin" ?
                       <Grid.Row>
                           <Grid.Column width={8}>
                               <Form>
                                   <Form.Field>
                                       <label>{i18n.t("user.chooseProducer")}</label>
                                       <Dropdown
                                           key="producerSelector"
                                           placeholder={i18n.t("user.searchProducer")}
                                           fluid
                                           labeled
                                           icon='dropdown'
                                           search
                                           selection
                                           options={producerOptions}
                                           onChange={ this.producerSelected.bind(this) }
                                           value={this.state.selectedProducer}
                                       />
                                   </Form.Field>
                               </Form>
                           </Grid.Column>
                           <GridColumn>
                               <UploadPdf />
                           </GridColumn>
                       </Grid.Row> : null }
                   <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={4}>
                        <Dropdown key="yearSelector"
                                  selection  direction='right' color='black'
                                  fluid
                                  label={"Jahr"}
                                  options={ this.yearOptions }
                                  value={ this.state.year }
                                  onChange={this.yearChanged.bind(this)}/>
                     </Grid.Column>
                     <Grid.Column mobile={16} tablet={16} computer={12}>
                         <Button.Group fluid floated='left'>
                             <Button active = {this.state.month === 1} onClick={ this.monthChanged.bind(this, 1)}>{ i18n.t("date.january")}</Button>
                             <Button active = {this.state.month === 2} onClick={ this.monthChanged.bind(this, 2)}>{ i18n.t("date.february")}</Button>
                             <Button active = {this.state.month === 3} onClick={ this.monthChanged.bind(this, 3)}>{ i18n.t("date.march")}</Button>
                             <Button active = {this.state.month === 4} onClick={ this.monthChanged.bind(this, 4)}>{ i18n.t("date.april")}</Button>
                             <Button active = {this.state.month === 5} onClick={ this.monthChanged.bind(this, 5)}>{ i18n.t("date.may")}</Button>
                             <Button active = {this.state.month === 6} onClick={ this.monthChanged.bind(this, 6)}>{ i18n.t("date.june")}</Button>
                             <Button active = {this.state.month === 7} onClick={ this.monthChanged.bind(this, 7)}>{ i18n.t("date.july")}</Button>
                             <Button active = {this.state.month === 8} onClick={ this.monthChanged.bind(this, 8)}>{ i18n.t("date.august")}</Button>
                             <Button active = {this.state.month === 9} onClick={ this.monthChanged.bind(this, 9)}>{ i18n.t("date.september")}</Button>
                             <Button active = {this.state.month === 10} onClick={ this.monthChanged.bind(this, 10)}>{ i18n.t("date.october")}</Button>
                             <Button active = {this.state.month === 11} onClick={ this.monthChanged.bind(this, 11)}>{ i18n.t("date.november")}</Button>
                             <Button active = {this.state.month === 12} onClick={ this.monthChanged.bind(this, 12)}>{ i18n.t("date.december")}</Button>
                         </Button.Group>
                     </Grid.Column>
                   </Grid.Row>
                   </Grid>
                       { (this.user.role === "producer") || (this.user.role === "admin") ?
                               <DeliveryList tbSoftNr={this.state.tbSoftNr} producerNr={this.state.selectedProducer} customerNr={this.state.customerNr} year={this.state.year} month={this.state.month} />
                            : null }
                       { (this.user.role === "customer") ?
                           <Grid.Column mobile={16} tablet={16} computer={16}>
                               <DeliveryList producerNr={this.state.producerNr} customerNr={this.state.customerNr} year={this.state.year} month={this.state.month} />
                           </Grid.Column> : null}

            </div>
        );
    }
}

