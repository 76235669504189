import React from "react"
import ReactDOM from "react-dom"
import {Provider} from "mobx-react";
import Layout from "./pages/Layout"
import './i18n';
import initStores from "./stores/initStores";
const stores = initStores(window.STORES);
const app = document.getElementById('app');

ReactDOM.render(
    <Provider stores={stores}>
        <Layout />
    </Provider>,
    app);