import React from "react";
import {
    Button,
    Divider,
    Header,
    Form,
    Grid,
    Modal,
    Table,
    TableHeader,
    Input,
    Checkbox,
    Dropdown
} from "semantic-ui-react";

import {inject, observer} from "mobx-react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {withTranslation} from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser } from "@fortawesome/free-solid-svg-icons";

import TextEdit from "../components/TextEdit";
import {action} from "mobx";

@inject("stores")
@withTranslation()
@observer
export default class AdminPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchInternal: "",
            isAddModalOpen: false,
            addUsername: "",
            addName: "",
            addPassword: "",
            addProducerNr: "",
            addActive: true,
            addTbSoftNr: "",
            pwdScale: {},
            isPwdModalOpen: false,
            newPassword: ""
        };
         this.userStore = props.stores.userStore;
         this.scaleStore = props.stores.scaleStore;
         this.user = this.userStore.loadUser();
    }

    componentDidMount() {
        if (this.user.role === "admin") {
            this.scaleStore.fetchScales();
        }
    }

    searchInternalChanged(e) {
        this.setState({searchInternal: e.target.value});
    }

    addNameChanged(e) {
        this.setState({addName : e.target.value});
    }

    addTbSoftNr(e) {
        this.setState({addTbSoftNr : e.target.value});
    }

    addUsernameChanged(e) {
        this.setState({addUsername : e.target.value});
    }
    addProducerNrChanged(e) {
        this.setState({addProducerNr : e.target.value});
    }

    addPasswordChanged(e) {
        this.setState({addPassword : e.target.value});
    }

    saveUser() {
         this.scaleStore.addScale(
            this.state.addPassword,
            this.state.addUsername,
            this.state.addName,
            parseInt(this.state.addProducerNr),
            parseInt(this.state.addTbSoftNr),
            this.state.addActive ? 1:0
        )
        this.setState({
            addUsername: "",
            addName: "",
            addProducerNr: "",
            addActive: true,
            isAddModalOpen: false})
    }

    newScale() {
        this.setState({isAddModalOpen : true})
    }

    updateScale(scale) {
        this.scaleStore.updateScale(scale.id, scale.username, scale.name, scale.producerNr, scale.tbSoftNr, scale.isActive ? 1 : 0)
    }

    producerNrChanged(scale, text) {
        scale.producerNr = parseInt(text)
        this.updateScale(scale);
    }

    tbSoftNrChanged(scale, text) {
        scale.tbSoftNr = parseInt(text)
        this.updateScale(scale);
    }


    usernameChanged(scale, text) {
        scale.username = text
        this.updateScale(scale);
    }

    nameChanged(scale, text) {
        scale.name = text
        this.updateScale(scale);
    }

    activeChanged(scale,  e, {checked}) {
        scale.isActive = checked ? 1 : 0;
        this.updateScale(scale);
    }
    
    
    newScale() {
        this.setState({ isAddModalOpen: true })
    }

    newPasswordChanged(a,b) {
        this.setState({newPassword: b.value});
    }

    newPasswordClicked(scale) {
        this.setState({isPwdModalOpen:true, pwdScale: scale  })
    }

    savePassword() {
        this.setState({newPassword: "", isPwdModalOpen: false});
        this.scaleStore.updateScalePassword(this.state.pwdScale.id, this.state.newPassword);
    }

    deleteScale(scale) {
        this.scaleStore.deleteScale(scale.id)
    }


    render() {
        let { scales } = this.scaleStore;
        const {i18n} = this.props;
        let scaleTable = [];
        for (let scale of scales) {
            if ((scale.name.toLowerCase().search(this.state.searchInternal.toLowerCase()) !== -1)
                || (scale.producerNr.toLowerCase().search(this.state.searchInternal.toLowerCase()) !== -1)
                || (scale.tbSoftNr.toLowerCase().search(this.state.searchInternal.toLowerCase()) !== -1)) {
                scaleTable.push(
                    <Table.Row key={"scale_active_" + scale.id}>
                        <Table.Cell><TextEdit text={scale.tbSoftNr} textChangedCallback={this.tbSoftNrChanged.bind(this, scale)}/></Table.Cell>
                        <Table.Cell><TextEdit text={scale.producerNr} textChangedCallback={this.producerNrChanged.bind(this, scale)}/></Table.Cell>
                        <Table.Cell><TextEdit text={scale.username} textChangedCallback={this.usernameChanged.bind(this, scale)}/></Table.Cell>
                        <Table.Cell><TextEdit text={scale.name} textChangedCallback={this.nameChanged.bind(this, scale)}/></Table.Cell>
                        <Table.Cell><Checkbox label={i18n.t("scale.active")} checked={ scale.isActive === 1 } onChange={this.activeChanged.bind(this, scale)}/> </Table.Cell>
                        <Table.Cell><Button size="small" onClick={this.newPasswordClicked.bind(this, scale)}><Icon name="undo alternate"/></Button></Table.Cell>
                        <Table.Cell><Button basic color='red' size="small" onClick={this.deleteScale.bind(this, scale)}><Icon name="delete"/></Button></Table.Cell>
                    </Table.Row>)
            }
        }

        return (
            <div>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <h2>{i18n.t("scale.scales")}</h2>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <Input fluid placeholder={ i18n.t("scale.filter") + "..."} onChange={this.searchInternalChanged.bind(this)}/>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Button fluid onClick={this.newScale.bind(this)}> <FontAwesomeIcon icon={faUser} fixedWidth />{"   " + i18n.t("scale.newScale")}</Button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <div style={{overflowX: "auto", minHeight: "500px"}}>
                                <Table celled striped>
                                    <TableHeader>
                                        <Table.Row>
                                            <Table.HeaderCell>{ i18n.t("user.tbSoftNr")}</Table.HeaderCell>
                                            <Table.HeaderCell>{ i18n.t("scale.producerNr")}</Table.HeaderCell>
                                            <Table.HeaderCell>{ i18n.t("scale.username")}</Table.HeaderCell>
                                            <Table.HeaderCell>{ i18n.t("scale.name")}</Table.HeaderCell>
                                            <Table.HeaderCell>{ i18n.t("scale.isActive")}</Table.HeaderCell>
                                            <Table.HeaderCell>{ i18n.t("scale.changePassword")}</Table.HeaderCell>
                                            <Table.HeaderCell>{ i18n.t("page.delete")}</Table.HeaderCell>
                                        </Table.Row>
                                    </TableHeader>
                                    <Table.Body>
                                        { scaleTable }
                                    </Table.Body>
                                </Table>
                            </div>
                        </Grid.Column>
                     </Grid.Row>
                </Grid>
                <Modal
                    closeIcon
                    className={"modal-top"}
                    open={this.state.isAddModalOpen}
                    onClose={() => this.setState({isAddModalOpen: false})}
                    onOpen={() => setOpen(true)}>
                    <Header icon='user' content={ i18n.t( "scale.newScale") } />
                    <Modal.Content>
                        <Form>
                            <Form.Input width={8} label={i18n.t("scale.username")} onChange={this.addUsernameChanged.bind(this) } placeholder={i18n.t("scale.username")}/>
                            <Form.Input width={8} label={i18n.t("scale.name")} onChange={this.addNameChanged.bind(this) } placeholder={i18n.t("scale.name")}/>
                            <Form.Input width={8} label={i18n.t("scale.tbSoftNr")} onChange={this.addTbSoftNr.bind(this)} placeholder={i18n.t("scale.tbSoftNr")}/>
                            <Form.Input width={8} label={i18n.t("scale.producerNr")} onChange={this.addProducerNrChanged.bind(this)} placeholder={i18n.t("scale.producerNr")}/>
                            <Form.Input width={8} label={i18n.t("scale.password")} value={this.state.addPassword} onChange={this.addPasswordChanged.bind(this)} placeholder={i18n.t("scale.password")}/>
                            <Form.Checkbox checked={this.state.addActive} onChange={(e) => {this.setState({addActive: e.target.value})}} label={i18n.t("scale.active")} />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={() => this.setState({isAddModalOpen: false})}>
                            <Icon name='remove' /> { i18n.t("page.cancel")}
                        </Button>
                        <Button disabled={ this.state.addUsername.length < 3 || this.state.addName.length < 3 || this.state.addPassword.length < 8 } color='green' onClick={this.saveUser.bind(this)}>
                            <Icon name='checkmark' /> { i18n.t("page.save")}
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal
                    closeIcon
                    className={"modal-top"}
                    open={this.state.isPwdModalOpen}
                    onClose={() => this.setState({isPwdModalOpen: false})}
                    onOpen={() => setOpen(true)}>
                    <Header icon='user' content={ i18n.t( "scale.newPassword") } />
                    <Modal.Content>
                        <Form>
                            <Form.Input width={8} label={i18n.t("scale.newPassword")} onChange={this.newPasswordChanged.bind(this) } placeholder={i18n.t("scale.newPassword")}/>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={() => this.setState({isPwdModalOpen: false})}>
                            <Icon name='remove' /> { i18n.t("page.cancel")}
                        </Button>
                        <Button disabled={false } color='green' onClick={this.savePassword.bind(this)}>
                            <Icon name='checkmark' /> { i18n.t("page.save")}
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}