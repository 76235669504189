import React from "react";
import {
    Button,
    Form,
    Grid,
    Table,
    Checkbox, Divider
} from "semantic-ui-react";

import {inject, observer} from "mobx-react";
import {withTranslation} from "react-i18next";

import { passwordStrength } from 'check-password-strength'
import TextEdit from "../components/TextEdit";

@inject("stores")
@withTranslation()
@observer
export default class CustomerSettingsPage extends React.Component {
    constructor(props) {
        super(props);
        this.userStore = props.stores.userStore;
        this.user = this.userStore.loadUser();
        let producerNr = "";
        if (this.user.role === "consumer") {
            console.log("consumer in datapages")
        }
        this.state = {
            searchInternal: "",
            isPwdModalOpen: false,
            password: "",
            repeatPassword: "",
            passwordStrengthValue: 0,
            showPassword: false
        };
    }

    componentDidMount() {
        this.userStore.fetchCustomersById(this.user.id);
    }

    searchInternalChanged(e) {
        this.setState({searchInternal: e.target.value});
    }


    newCustomer() {
        this.setState({ isAddModalOpen: true})
    }

    newPasswordChanged(a,b) {
        this.setState({newPassword: b.value});
    }

    newPasswordClicked(user) {
        this.setState({isPwdModalOpen:true, pwdUser: user  })
    }

    savePassword() {
        let customer = this.userStore.customer;
        const {i18n} = this.props;
        let successMsg = i18n.t("customer.passwordSuccess");
        let errorMsg = i18n.t("customer.passwordError");
        this.userStore.updateCustomerPassword(customer.id, this.state.password, successMsg, errorMsg);
        this.setState({password: "", passwordRepeat: "", isPwdModalOpen: false});
    }

    initCustomerPasswort(customer) {
        this.userStore.initCustomerPasswort(customer.id);
    }

    passwordChanged(e) {
        let strength = passwordStrength(e.target.value);
        this.setState({
            passwordStrengthValue : strength.id,
            password: e.target.value
        })
    }

    repeatPasswordChanged(e) {
        this.setState({
            repeatPassword: e.target.value
        })
    }

    showPasswordChanged(e, {checked}) {
        this.setState({showPassword : checked})
    }

    portalEmailChanged(text) {
        let customer = this.userStore.customer;
        this.userStore.updateCustomerPortalEmail(customer.id, text)
    }

    render() {
        const {i18n} = this.props;
        let customer = this.userStore.customer;
        return (
             <div>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <h1>{i18n.t("customer.data")}</h1>
                        </Grid.Column>
                    </Grid.Row>
                    { customer.passwordInitialized === 0 ?
                    <Grid.Row>
                        <Grid.Column>
                            <h1 style={{color: "#FF0000"}}>{i18n.t("password.passwordMustBeChanged")}</h1>
                        </Grid.Column>
                    </Grid.Row> : null }
                    <Grid.Row style={{paddingTop: "20px"}}>
                        <Grid.Column>
                            <Form>
                                <Form.Group>
                                    <Form.Input onChange={this.passwordChanged.bind(this)} type={this.state.showPassword ? "text" : "password"} placeholder={i18n.t("password.password")} label={i18n.t("password.password")} />
                                    <Form.Input onChange={this.repeatPasswordChanged.bind(this)} type={this.state.showPassword ? "text" : "password"} placeholder={i18n.t("password.repeatPassword")} label={i18n.t("password.repeatPassword")}/>
                                </Form.Group>
                                <Form.Field>
                                    <Checkbox value={this.state.showPassword} label={i18n.t("password.showPassword")} onChange={this.showPasswordChanged.bind(this)}/>
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            { this.state.password.length > 0 &&  this.state.passwordStrengthValue === 0 ? <p style={{color: "#FF0000"}}>{i18n.t("password.tooWeak")}</p> : null }
                            { this.state.passwordStrengthValue === 1 ? <p style={{color: "#FF9933"}}>{i18n.t("password.weak")}</p> : null }
                            { this.state.passwordStrengthValue === 2 ? <p style={{color: "#16AB39"}}>{i18n.t("password.medium")}</p> : null }
                            { this.state.passwordStrengthValue === 3 ? <p style={{color: "#16AB39"}}>{i18n.t("password.strong")}</p> : null }
                            { this.state.password.length > 0 && (this.state.password !== this.state.repeatPassword) ? <p style={{color: "#FF0000"}}>{i18n.t("password.notSame")}</p> : null }
                            { this.state.password.length > 0 && this.state.password.length <= 8 ? <p style={{color: "#FF0000"}}>{i18n.t("password.tooSmall")}</p> : null }
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Button onClick={this.savePassword.bind(this)} disabled={this.state.password.length <= 8 || this.state.passwordStrengthValue === 0 || (this.state.password !== this.state.repeatPassword)} positive>{i18n.t("password.savePassword")}</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                    { customer.hasOwnProperty("customerName") ?
                        <div>
                        <Divider/>
                        <Grid>
                            <Grid.Row columns={1} style={{paddingTop: "20px"}}>
                                <Grid.Column>
                                    <h3>{i18n.t ("customer.emailLogin")}</h3>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column >
                                    {i18n.t ("customer.emailInfo")}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column width={8}>
                                    {i18n.t("customer.latestEmail") + ":" }
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    {i18n.t("customer.portalEmail")+ ":"  }
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column width={8}>
                                    <b>{customer.latestEmailFromDelivery}</b>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <TextEdit text={customer.portalEmail} textChangedCallback={this.portalEmailChanged.bind(this)}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Divider/>
                        </div>: null}
                <Grid>
                    <Grid.Row style={{paddingTop: "30px"}}>
                        <Grid.Column>
                            { customer.hasOwnProperty("customerName") ?
                                <div>
                                    <h3>{i18n.t("customer.dataLastDelivery")}</h3>
                                    <Table celled >
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell> {i18n.t("customer.customerNr")}</Table.Cell>
                                                <Table.Cell> {customer.customerNr}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell> {i18n.t("customer.customerName")}</Table.Cell>
                                                <Table.Cell> {customer.customerName} </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell> {i18n.t("customer.customerAdditionalName")}</Table.Cell>
                                                <Table.Cell> {customer.customerAdditionalName} </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell> {i18n.t("customer.customerStreet")}</Table.Cell>
                                                <Table.Cell> {customer.customerStreet} </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell> {i18n.t("customer.zipcity")}</Table.Cell>
                                                <Table.Cell> {customer.customerZip + " " + customer.customerCity} </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell> {i18n.t("customer.tbSoftNr")}</Table.Cell>
                                                <Table.Cell> {customer.tbSoftNr} </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell> {i18n.t("customer.producerNr")}</Table.Cell>
                                                <Table.Cell> {customer.producerNr} </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </div>
                                : null }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </div>
        );
    }
}