import config from "../../config/main.config"

export function isDevelop() {
    return process.env.NODE_ENV === 'development' || typeof process.env.NODE_ENV === 'undefined';
}

export function isAdmin() {
    let saved = JSON.parse(localStorage.getItem(config.STORAGE_KEY));
    if (saved === null) {
        return false;
    }
    return saved.user.role === "admin";
}

export function isProducer() {
    let saved = JSON.parse(localStorage.getItem(config.STORAGE_KEY));
    if (saved === null) {
        return false;
    }
    return saved.user.role === "producer";
}

export function isCustomer() {
    let saved = JSON.parse(localStorage.getItem(config.STORAGE_KEY));
    if (saved === null) {
        return false;
    }

    return saved.user.role === "customer";
}


export function getTokenFromLocalStorage() {
    let saved = JSON.parse(localStorage.getItem(config.STORAGE_KEY));
    if (saved === null) {
        return null;
    }
    return saved.user.hasOwnProperty("token") ? saved.user.token : null;
}

export function getRolesFromLocalStorage() {
    let saved = JSON.parse(localStorage.getItem(config.STORAGE_KEY));

    if (saved === null || saved.user.hasOwnProperty("roles") === false) {
        return null;
    }

    return saved.user.roles;
}

export function getUserFromLocalStorage() {
    let saved = JSON.parse(localStorage.getItem(config.STORAGE_KEY));

    if (saved === null || saved.hasOwnProperty("user") === false) {
        return null;
    }

    return saved.user;
}

export function removeUserFromStorage() {
    if (typeof window.localStorage !== "undefined") {
        localStorage.removeItem(config.STORAGE_KEY);
    }
}

export function setUserLocalStorage(user) {
    if (typeof window.localStorage !== "undefined") {
        let object = {
            user: user,
            timestamp: new Date().getTime()
        };
        localStorage.setItem(config.STORAGE_KEY, JSON.stringify(object));
    }
}

export function getDateWithoutTime(date) {
    let d = date;
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let sMonth = month < 10 ? "0" + month : ""+ month;

    let day = d.getDate();
    let sDay = day < 10 ? "0" + day : ""+ day;

    return parseInt( "" + year + sMonth + sDay);
}

export function start2end(start, end, pauseMin) {
    console.log(start + " - " + end + " - " + pauseMin)
    let minutes =  (end.hour()*60 + end.minute()) - (start.hour()*60 + start.minute()) - pauseMin;
    return {
        noMins: minutes,
        hours: Math.floor(minutes / 60),
        minutes: minutes % 60
    }
}

// REGEX
// E-Mail [a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?