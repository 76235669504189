import {action, observable} from 'mobx';
import config from "../../config/main.config";
import {getTokenFromLocalStorage} from "../helper/util";
import history from "../helper/browserHistory"
import dayjs from "dayjs";
const FileSaver = require('file-saver');

class DeliveryStore {
    @observable delivery = [];
    @observable selectedMonth = dayjs().month()+1;
    @observable selectedYear = dayjs().year();
    @observable selectedTbSoftNr = -1;
    @observable selectedProducerNr = -1;
    @observable selectedCustomerNr = -1;

    @observable loading = false;
    @observable error = false;
    @observable fetching = false;
    @observable syncLog = [];

    setSelectedMonthYear(month, year) {
        this.selectedMonth = month;
        this.selectedYear = year;
    }

    setSelectedTbSoftProducer(tbSoftNr, producerNr) {
        this.selectedTbSoftNr = tbSoftNr;
        this.selectedProducerNr = producerNr;
    }

    setSelectedCustomerNr(customerNr) {
        this.selectedCustomerNr = customerNr;
    }

    @action setChecked(deliveryId, isChecked) {
        let delivery = this.delivery.find(del => del.id === deliveryId);
        delivery.isChecked = isChecked;
    }

    @action fetchDelivery() {
        this.fetching = true;
        return fetch(config.BASE_URL + "delivery/", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                "customernr": this.selectedCustomerNr,
                "producernr": this.selectedProducerNr,
                "tbsoftnr": this.selectedTbSoftNr,
                "month": this.selectedMonth,
                "year": this.selectedYear
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.fetching = false;
                    this.delivery = json.delivery.map(del => { del.isChecked = false; return del;});
                })
            } else {
                this.fetching = false;
            }
        }).catch(
            error => {
                this.fetching = false;
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
    }


    postPdfFile(file, deliveryNr) {
        const data = new FormData();
        data.append("pdf", file);
        this.fetching = true;
        return fetch(config.BASE_URL + "document/", {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                "deliverynr": deliveryNr
            },
            body: data
        }).then(response => {
            this.syncExcel = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.fetching = false;
                })
            } else {
                this.fetching = false;
            }
        }).catch(
            error => {
                this.fetching = false;
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
    }

    fetchPdf(deliverynr) {
        this.fetching = true;
        let filename = "ls_" + deliverynr + ".pdf"
        return fetch(config.BASE_URL + "document/", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/pdf",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                "deliverynr": deliverynr
            },
            responseType: 'blob'
        }).then(response => {
            this.fetchDelivery();
            return response.blob();
        }).then(blob => FileSaver.saveAs(blob, filename))
            .catch(
                error => {
                    this.fetching = false;
                    if (error.status === 401) {
                    }
                }
            );
    }

    downloadMultipleFiles(deliveryIds) {
        this.fetching = true;
        let filename = ""
        return fetch(config.BASE_URL + "document/multi", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/pdf",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                "deliveryids": JSON.stringify({deliveryIds: deliveryIds})
            },
            responseType: 'blob'
        }).then((response) => {
            filename = dayjs().format("YYYY-MM-DD_HH:mm:ss") + "_TBSoft_Lieferscheine.zip";
            return response.blob();
        }).then(blob => {
            this.fetchDelivery();
            FileSaver.saveAs(blob, filename)
        })
          .catch(
             error => {
                 this.fetching = false;
                 if (error.status === 401) {
                 }
             }
         );
    }

    downloadCsv(allCustomers, startDate, endDate) {
        this.fetching = true;
        let filename = startDate+"-"+endDate + "-";
        let customerNr = "";
        if (allCustomers) {
            filename += "all.csv";
            customerNr = "all";
        } else {
            filename += this.selectedCustomerNr+".csv";
            customerNr = this.selectedCustomerNr;
        }
        return fetch(config.BASE_URL + "delivery/csv", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/pdf",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                "tbsoftnr": this.selectedTbSoftNr,
                "producernr": this.selectedProducerNr,
                "customernr": customerNr,
                "startdate": startDate,
                "enddate": endDate

            },
            responseType: 'blob'
        }).then(response => {
            this.fetchDelivery();
            return response.blob();
        }).then(blob => FileSaver.saveAs(blob, filename))
            .catch(
                error => {
                    this.fetching = false;
                    if (error.status === 401) {
                    }
                }
            );
    }


}
export default DeliveryStore;

