import React from "react";
import {
    Button,
    Divider,
    Header,
    Form,
    Grid,
    Modal,
    Table,
    TableHeader,
    Input,
    Checkbox,
    Dropdown
} from "semantic-ui-react";

import {inject, observer} from "mobx-react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {withTranslation} from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser } from "@fortawesome/free-solid-svg-icons";

import TextEdit from "../components/TextEdit";
import {action} from "mobx";
import EmailTemplate from "../components/EmailTemplate";
import UploadPdf from "../components/UploadPdf";
import UploadLogo from "../components/UploadLogo";

@inject("stores")
@withTranslation()
@observer
export default class ProducerPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchInternal: "",
            isAddModalOpen: false,
            addPassword: "",
            addPrename: "",
            addSurname: "",
            addUsername: "",
            addCompany: "",
            addEmail: "",
            addSendEmail: "",
            addStreet: "",
            addStreetNumber: "",
            addZip: "",
            addCity: "",
            addPhone: "",
            addTbSoftNr: 0,
            addProducerNr: 0,
            addProducerPin: 0,
            pwdUser: {},
            isPwdModalOpen: false,
            newPassword: "",
            addActive: true,
            addUsernameInvalid: false,
        };
         this.userStore = props.stores.userStore;
         this.user = this.userStore.loadUser();
    }

    componentDidMount() {
        if (this.user.role === "admin") {
            this.userStore.fetchProducers();
        }
    }

    searchInternalChanged(e) {
        this.setState({searchInternal: e.target.value});
    }

    addPrenameChanged(e) {
        this.generateUserName(e.target.value, this.state.addSurname);
        this.setState({addPrename : e.target.value});
    }

    addSurnameChanged(e) {
        this.generateUserName(this.state.addPrename, e.target.value);
        this.setState({addSurname : e.target.value});
    }

    addUsernameChanged(e) {
        this.generateUserName(e.target.value, "");
    }
    
    saveUser() {
             this.userStore.addProducer(
                this.state.addPassword,
                this.state.addPrename,
                this.state.addSurname,
                this.state.addUsername,
                this.state.addEmail,
                this.state.addSendEmail,
                this.state.addStreet,
                this.state.addStreetNumber,
                this.state.addZip,
                this.state.addCity,
                this.state.addPhone,
                this.state.addCompany,
                this.state.addActive,
                this.state.addProducerNr,
                this.state.addProducerPin,
                this.state.addTbSoftNr
            )
        this.setState({
            addPassword: '',
            addPrename: '',
            addSurname: '',
            addUsername: '',
            addEmail: '',
            addSendEmail: '',
            addStreet: '',
            addStreetNumber: '',
            addZip: '',
            addCity: '',
            addPhone: '',
            addCompany: '',
            addActive: '',
            producerNr: '',
            customerNr: '',
            producerPin: '',
            addTbSoftNr: '',
            isAddModalOpen: false});
    }

    newAdmin() {
        this.setState({isAddModalOpen : true})
    }

    checkUsername(username) {
        let { users } = this.userStore;
        let isValid = users.find(user => user.username === username) === undefined && username.length >= 2
        return isValid;
    }

    generateUserName(prename, surname) {
        let { admins } = this.userStore;
        let username = prename + surname;
        this.setState({addUsername : username});
        if (admins.find(user => user.username === username) === undefined) {
            this.setState({addUsername : username});
        } else {
            // find all similiar usernames starting with the username
            for (let i = 1; i<50; i++) {
                if (admins.find(user => user.username === username+i.toString()) === undefined) {
                    this.setState({addUsername: username+i.toString()})
                    break;
                }
            }
        }
    }

    updateProducer(user) {
        this.userStore.updateProducer(user.id, user.prename, user.surname, user.username, user.email, user.sendEmail, user.street, user.streetNumber, user.zip, user.city, user.phone, user.companyName, user.isActive, user.producerNr, user.producerPin, user.tbSoftNr)
    }

    prenameChanged(user, text) {
        user.prename = text
        this.updateProducer(user);
    }

    surnameChanged(user, text) {
        user.surname = text
        this.updateProducer(user);
    }

    activeChanged(user,  e, {checked}) {
        user.isActive = checked ? 1 : 0;
        this.updateProducer(user);
    }

    emailChanged(user, text) {
        user.email = text
        this.updateProducer(user);
    }

    sendEmailChanged(user, text) {
        user.sendEmail = text
        this.updateProducer(user);
    }

    usernameChanged(user, text) {
        if (this.checkUsername(text) === true) {
            user.username = text
            this.updateProducer(user);
        } else {
            alert( this.props.i18n.t("user.usernameAlreadyExists"))
        }
    }

    companyChanged(user, text) {
        user.companyName = text;
        this.updateProducer(user);
    }

    streetChanged(user, text) {
        user.street = text
        this.updateProducer(user);
    }

    streetNumberChanged(user, text) {
        user.streetNumber = text
        this.updateProducer(user);
    }

    zipChanged(user, text) {
        user.zip = text
        this.updateProducer(user);
    }

    cityChanged(user, text) {
        user.city = text
        this.updateProducer(user);
    }

    phoneChanged(user, text) {
        user.phone = text
        this.updateProducer(user);
    }
    
    producerNrChanged(user, text) {
        user.producerNr =  parseInt(text);
        this.updateProducer(user);
    }

    tbSoftNrChanged(user, text) {
        user.tbSoftNr =  parseInt(text);
        this.updateProducer(user);
    }


    producerPinChanged(user, text) {
        const {i18n} = this.props;
        if (!(/^\d+$/.test(text)) ||  (text.length !== 6)) {
            alert(i18n.t("user.pinLength"));
        }
        user.producerPin = parseInt(text);
        this.updateProducer(user);
    }

    
    newAdmin() {
        this.setState({ isAddModalOpen: true })
    }

    newPasswordChanged(a,b) {
        this.setState({newPassword: b.value});
    }

    newPasswordClicked(user) {
        this.setState({isPwdModalOpen:true, pwdUser: user  })
    }

    savePassword() {
        this.setState({newPassword: "", isPwdModalOpen: false});
        const {i18n} = this.props;
        this.userStore.updateUserPassword(this.state.pwdUser.id, this.state.newPassword, "producer", i18n.t("customer.passwordSuccess"), i18n.t("customer.passwordError"));
    }

    render() {
        const {i18n} = this.props;
        let { producers } = this.userStore;
        let producerTable = [];
        if (producers.length > 0) {
            for (let user of producers) {
                if ((user.username.toLowerCase().search(this.state.searchInternal.toLowerCase()) !== -1)
                    || (user.prename.toLowerCase().search(this.state.searchInternal.toLowerCase()) !== -1)
                    || (user.surname.toLowerCase().search(this.state.searchInternal.toLowerCase()) !== -1)
                    || (user.email.toLowerCase().search(this.state.searchInternal.toLowerCase()) !== -1)
                    || (user.companyName.toLowerCase().search(this.state.searchInternal.toLowerCase()) !== -1)
                    || (user.street.toLowerCase().search(this.state.searchInternal.toLowerCase()) !== -1)
                    || (user.streetNumber.toLowerCase().search(this.state.searchInternal.toLowerCase()) !== -1)
                    || (user.zip.toLowerCase().search(this.state.searchInternal.toLowerCase()) !== -1)
                    || (user.city.toLowerCase().search(this.state.searchInternal.toLowerCase()) !== -1)
                    || (user.phone.toLowerCase().search(this.state.searchInternal.toLowerCase()) !== -1)
                    || (user.tbSoftNr.toString().search(this.state.searchInternal.toLowerCase()) !== -1)
                    || (user.producerNr.toString().search(this.state.searchInternal.toLowerCase()) !== -1)
                    || (user.producerPin.toString().search(this.state.searchInternal.toLowerCase()) !== -1)) {
                    producerTable.push(
                        <Table.Row key={"user_active_" + user.id}>
                            <Table.Cell><TextEdit text={user.tbSoftNr}
                                                  textChangedCallback={this.tbSoftNrChanged.bind(this, user)}/></Table.Cell>
                            <Table.Cell><TextEdit text={user.producerNr}
                                                  textChangedCallback={this.producerNrChanged.bind(this, user)}/></Table.Cell>
                            <Table.Cell><TextEdit text={user.username}
                                                  textChangedCallback={this.usernameChanged.bind(this, user)}/></Table.Cell>
                            <Table.Cell><TextEdit text={user.companyName}
                                                  textChangedCallback={this.companyChanged.bind(this, user)}/></Table.Cell>
                            <Table.Cell><TextEdit text={user.prename}
                                                  textChangedCallback={this.prenameChanged.bind(this, user)}/></Table.Cell>
                            <Table.Cell><TextEdit text={user.surname}
                                                  textChangedCallback={this.surnameChanged.bind(this, user)}/></Table.Cell>
                            <Table.Cell><TextEdit text={user.email}
                                                  textChangedCallback={this.emailChanged.bind(this, user)}/></Table.Cell>
                            <Table.Cell><TextEdit text={user.sendEmail}
                                                  textChangedCallback={this.sendEmailChanged.bind(this, user)}/></Table.Cell>
                            <Table.Cell><TextEdit text={user.street}
                                                  textChangedCallback={this.streetChanged.bind(this, user)}/></Table.Cell>
                            <Table.Cell><TextEdit text={user.streetNumber}
                                                  textChangedCallback={this.streetNumberChanged.bind(this, user)}/></Table.Cell>
                            <Table.Cell><TextEdit text={user.zip}
                                                  textChangedCallback={this.zipChanged.bind(this, user)}/></Table.Cell>
                            <Table.Cell><TextEdit text={user.city}
                                                  textChangedCallback={this.cityChanged.bind(this, user)}/></Table.Cell>
                            <Table.Cell><TextEdit text={user.phone}
                                                  textChangedCallback={this.phoneChanged.bind(this, user)}/></Table.Cell>
                            <Table.Cell><EmailTemplate producerNr={user.producerNr} tbSoftNr={user.tbSoftNr}/></Table.Cell>
                            <Table.Cell><UploadLogo producerNr={user.producerNr} tbSoftNr={user.tbSoftNr}/></Table.Cell>
                            <Table.Cell><TextEdit text={user.producerPin}
                                                  textChangedCallback={this.producerPinChanged.bind(this, user)}/></Table.Cell>
                            <Table.Cell><Checkbox label={i18n.t("user.active")} checked={user.isActive === 1}
                                                  onChange={this.activeChanged.bind(this, user)}/> </Table.Cell>
                            <Table.Cell><Button size="small" onClick={this.newPasswordClicked.bind(this, user)}><Icon name="undo alternate"/></Button></Table.Cell>
                        </Table.Row>)
                }
            }
        }

        return (
            <div>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <h2>{i18n.t("user.producers")}</h2>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <Input fluid placeholder={ i18n.t("user.filter") + "..."} onChange={this.searchInternalChanged.bind(this)}/>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Button fluid onClick={this.newAdmin.bind(this)}> <FontAwesomeIcon icon={faUser} fixedWidth />{"   " + i18n.t("user.newProducer")}</Button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <div style={{overflowX: "auto", minHeight: "500px"}}>
                            <Table celled striped>
                                <TableHeader>
                                    <Table.Row>
                                        <Table.HeaderCell>{ i18n.t("user.tbSoftNr")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("user.producerNr")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("user.username")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("user.company")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("user.prename")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("user.surname")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("user.email")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("user.sendEmail")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("user.street")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("user.streetNumber")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("user.zip")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("user.city")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("user.phone")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("user.email")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("user.logo")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("user.producerPin")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("user.active")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("user.changePassword")}</Table.HeaderCell>
                                    </Table.Row>
                                </TableHeader>
                                <Table.Body>
                                    { producerTable }
                                </Table.Body>
                            </Table>
                            </div>
                        </Grid.Column>
                     </Grid.Row>
                </Grid>
                <Modal
                    closeIcon
                    className={"modal-top"}
                    open={this.state.isAddModalOpen}
                    onClose={() => this.setState({isAddModalOpen: false})}
                    onOpen={() => setOpen(true)}>
                    <Header icon='user' content={ i18n.t( "user.newProducer") } />
                    <Modal.Content>
                        <Form>
                            <Form.Group>
                                <Form.Input width={8} label={i18n.t("user.prename")} onChange={this.addPrenameChanged.bind(this) } placeholder={i18n.t("user.prename")}/>
                                <Form.Input width={8} label={i18n.t("user.surname")} onChange={this.addSurnameChanged.bind(this)} placeholder={i18n.t("user.surname")}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Input width={8} label={i18n.t("user.username")} value={this.state.addUsername} onChange={(e) => {this.setState({addUsername: e.target.value})}} placeholder={i18n.t("user.username")}/>
                                <Form.Input width={8} label={i18n.t("user.company")} value={this.state.addCompany} onChange={(e) => {this.setState({addCompany: e.target.value})}} placeholder={i18n.t("user.company")}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Input width={4} label={i18n.t("user.password")} value={this.state.addPassword} onChange={(e) => {this.setState({addPassword: e.target.value})}} placeholder={i18n.t("user.password")}/>
                                <Form.Input width={6} label={i18n.t("user.email")} value={this.state.addEmail} onChange={(e) => {this.setState({addEmail: e.target.value})}} placeholder={i18n.t("user.email")}/>
                                <Form.Input width={6} label={i18n.t("user.sendEmail")} value={this.state.addSendEmail} onChange={(e) => {this.setState({addSendEmail: e.target.value})}} placeholder={i18n.t("user.sendEmail")}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Input width={8} label={i18n.t("user.street")} value={this.state.addStreet} onChange={(e) => {this.setState({addStreet: e.target.value})}} placeholder={i18n.t("user.street")}/>
                                <Form.Input width={4} label={i18n.t("user.streetNumber")} value={this.state.addStreetNumber} onChange={(e) => {this.setState({addStreetNumber: e.target.value})}} placeholder={i18n.t("user.streetNumber")}/>
                                <Form.Input width={4} label={i18n.t("user.phone")} value={this.state.addPhone} onChange={(e) => {this.setState({addPhone: e.target.value})}} placeholder={i18n.t("user.phone")}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Input width={4} label={i18n.t("user.zip")} value={this.state.addZip} onChange={(e) => {this.setState({addZip: e.target.value})}} placeholder={i18n.t("user.zip")}/>
                                <Form.Input width={12} label={i18n.t("user.city")} value={this.state.addCity} onChange={(e) => {this.setState({addCity: e.target.value})}} placeholder={i18n.t("user.city")}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Input type = "number" width={5} label={i18n.t("user.tbSoftNr")} value={this.state.addTbSoftNr} onChange={(e) => {this.setState({addTbSoftNr: e.target.value})}} placeholder={i18n.t("user.tbSoftNr")}/>
                                <Form.Input type = "number" width={5} label={i18n.t("user.producerNr")} value={this.state.addProducerNr} onChange={(e) => {this.setState({addProducerNr: e.target.value})}} placeholder={i18n.t("user.producerNr")}/>
                                <Form.Input type = "number" width={5} label={i18n.t("user.producerPin")} value={this.state.addProducerPin} onChange={(e) => {this.setState({addProducerPin: e.target.value})}} placeholder={i18n.t("user.producerPin")}/>
                            </Form.Group>
                            <Form.Checkbox checked={this.state.addActive} onChange={(e) => {this.setState({addActive: e.target.value})}} label={i18n.t("user.active")} />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={() => this.setState({isAddModalOpen: false})}>
                            <Icon name='remove' /> { i18n.t("user.cancel")}
                        </Button>
                        <Button disabled={false } color='green' onClick={this.saveUser.bind(this)}>
                            <Icon name='checkmark' /> { i18n.t("user.save")}
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal
                    closeIcon
                    className={"modal-top"}
                    open={this.state.isPwdModalOpen}
                    onClose={() => this.setState({isPwdModalOpen: false})}
                    onOpen={() => setOpen(true)}>
                    <Header icon='user' content={ i18n.t( "user.newPassword") } />
                    <Modal.Content>
                        <Form>
                            <Form.Input width={8} label={i18n.t("user.newPassword")} onChange={this.newPasswordChanged.bind(this) } placeholder={i18n.t("user.newPassword")}/>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={() => this.setState({isPwdModalOpen: false})}>
                            <Icon name='remove' /> { i18n.t("user.cancel")}
                        </Button>
                        <Button disabled={false } color='green' onClick={this.savePassword.bind(this)}>
                            <Icon name='checkmark' /> { i18n.t("user.save")}
                        </Button>
                    </Modal.Actions>
                </Modal>

            </div>
        );
    }
}