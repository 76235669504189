import React from "react"
import {BrowserRouter, HashRouter, Route, Router, Switch} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {Grid} from "semantic-ui-react";
import NavigationBar from "../components/NavigationBar"
import Login from "./Login";
import { isAdmin, isCustomer, isProducer } from "../helper/util";

import history from "../helper/browserHistory"
import CustomerPage from "./CustomerPage";
import ProducerPage from "./ProducerPage";
import AdminPage from "./AdminPage";
import ScalePage from "./ScalePage";
import DeliveryPage from "./DeliveryPage";
import CustomerSettingsPage from "./CustomerSettingsPage";

import {withTranslation} from "react-i18next";

// Require scss files
require('../../stylesheets/_all.scss');

@inject("stores")
@withTranslation()
@observer
export default class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.userStore = props.stores.userStore;
    }

    render() {
    // needed for rendering
    const user = this.userStore.user;
    let isAdminRole = isAdmin();
    let isProducerRole = isProducer();
    let isCustomerRole = isCustomer();
    return (
            <Router history={history}>
                <div>
                <NavigationBar/>
                <Grid centered  className="app-container">
                    <Grid.Row style={{"paddingBottom": "0px"}}>
                        <Grid.Column style={{"paddingRight": "32px", "paddingTop": "32px"}} width={15}>
                            <Switch>
                                <Route exact path="/" component={Login}/>
                                { isAdminRole  || isProducerRole  || isCustomerRole ? <Route exact path="/delivery" component={DeliveryPage}/> : null }
                                { isAdminRole || isProducerRole ? <Route exact path="/customer" component={CustomerPage}/> : null }
                                { isAdminRole ? <Route exact path="/producer" component={ProducerPage}/> : null }
                                { isAdminRole ? <Route exact path="/admin" component={AdminPage}/> : null }
                                { isAdminRole ? <Route exact path="/scale" component={ScalePage}/> : null }
                                { isCustomerRole ? <Route exact path="/settings" component={CustomerSettingsPage}/> : null }
                            </Switch>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                </div>
            </Router>
        );
    }
}
