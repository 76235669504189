import {action, computed, observable} from 'mobx';
import config from "../../config/main.config";
import {
    getUserFromLocalStorage,
    getTokenFromLocalStorage,
    removeTokenFromStorage,
    setUserLocalStorage
} from "../helper/util";
import history from "../helper/browserHistory"

class UserStore {
    @observable user = null;

    @observable admins = [];
    @observable users = [];
    @observable producers = [];
    @observable customers = [];
    @observable customer = {};

    @observable loading = false;
    @observable error = false;

    @action logout() {
        removeTokenFromStorage();
        this.user = null;
    }

    @action loadUser() {
        return getUserFromLocalStorage();
    }

    @action authenticateUser(username, password, lg) {
        this.loading = true;
        return fetch(config.BASE_URL + "user/authenticate", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify({username, password})
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        setUserLocalStorage(json);
                        history.push("/delivery")
                        this.user = json;
                        if (this.user.role === "producer") {
                            this.producers = [this.users];
                        }
                        if (this.user.role === "customer") {
                            this.customers = [this.users];
                        }
                    });
                } else {
                    alert("Username / Password ungültig.")
                    removeTokenFromStorage();
                    this.error = true;
                    this.user = null;
                }
            })
            .catch(
                error => {
                    //TODO: Alert?
                    removeTokenFromStorage();
                    this.loading = false;
                    this.error = true;
                    this.user = null;
                    throw error;
                }
            );
    }

    @action fetchCustomers(tbSoftNr, producerNr) {
        this.loading = true;
        return fetch(config.BASE_URL + "user/customer", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                "producernr": producerNr,
                "tbsoftnr": tbSoftNr
            }
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        this.customers = json.users;
                    });
                } else {
                    this.error = true;
                    this.customers = [];
                }
            })
            .catch(
                error => {
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }

    @action fetchCustomersById(id) {
        this.loading = true;
        return fetch(config.BASE_URL + "user/customer/id", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                "customerid": id
            }
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        this.customer = json.costumer;
                    });
                } else {
                    this.error = true;
                    this.customer = [];
                }
            })
            .catch(
                error => {
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }


    @action addCustomer(password, prename, surname, username, email, street, streetNumber, zip, city, phone, company, isActive, tbSoftNr, producerNr, customerNr) {
        this.loading = true;
        return fetch(config.BASE_URL + "user/customer", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                password, prename, surname, username, email, street, streetNumber, zip, city, phone, company, isActive, tbSoftNr, producerNr, customerNr
            })
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.fetchCustomers(tbSoftNr, producerNr);
                });
            } else {
                this.error = true;
            }
        })
            .catch(
                error => {
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }

    @action updateCustomer(id, prename, surname, username, email, street, streetNumber, zip, city, phone, company, isActive, tbSoftNr, producerNr, customerNr) {
        this.loading = true;
        return fetch(config.BASE_URL + "user/customer", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                id, password, prename, surname, username, email, street, streetNumber, zip, city, phone, company, isActive, producerNr, customerNr, tbSoftNr
            })
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        this.fetchCustomers(tbSoftNr, producerNr);
                    });
                } else {
                    this.error = true;
                }
            })
            .catch(
                error => {
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }

    @action updateCustomerPortalEmail(id, email) {
        this.loading = true;
        return fetch(config.BASE_URL + "user/customer/email", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                id, email
            })
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {

                    });
                } else {
                    this.error = true;
                }
            })
            .catch(
                error => {
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }


    @action fetchProducers() {
        this.loading = true;
        return fetch(config.BASE_URL + "user/producer", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        this.producers = json.users;
                    });
                } else {
                    this.error = true;
                    this.producers = [];
                }
            })
            .catch(
                error => {
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }

    @action addProducer(password, prename, surname, username, email, sendEmail, street, streetNumber, zip, city, phone, company, isActive, producerNr, producerPin, tbSoftNr) {
        this.loading = true;
        return fetch(config.BASE_URL + "user/producer", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                password, prename, surname, username, email, sendEmail, street, streetNumber, zip, city, phone, company, isActive, producerNr, producerPin, tbSoftNr
            })
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.fetchProducers();
                });
            } else {
                this.error = true;
            }
        })
            .catch(
                error => {
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }

    @action updateProducer(id, prename, surname, username, email, sendEmail, street, streetNumber, zip, city, phone, company, isActive, producerNr, producerPin, tbSoftNr) {
        this.loading = true;
        return fetch(config.BASE_URL + "user/producer", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                id, prename, surname, username, email, sendEmail, street, streetNumber, zip, city, phone, company, isActive, producerNr, producerPin, tbSoftNr
            })
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        this.fetchProducers();
                    });
                } else {
                    this.error = true;
                }
            })
            .catch(
                error => {
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }

    @action fetchAdmins() {
        this.loading = true;
        return fetch(config.BASE_URL + "user/admin", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        this.admins = json.users;
                    });
                } else {
                    this.error = true;
                    this.users = [];
                }
            })
            .catch(
                error => {
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }

    @action addAdmin(password, prename, surname, username, email, street, streetNumber, zip, city, phone, company, isActive) {
        this.loading = true;
        return fetch(config.BASE_URL + "user/admin", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                password, prename, surname, username, email, street, streetNumber, zip, city, phone, company, isActive
            })
        }).then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        this.fetchAdmins();
                    });
                } else {
                    this.error = true;
                }
            })
            .catch(
                error => {
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }


    @action updateAdmin(id, prename, surname, username, email, street, streetNumber, zip, city, phone, company, isActive ) {
        this.loading = true;
        return fetch(config.BASE_URL + "user/admin", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                id, prename, surname, username, email, street, streetNumber, zip, city, phone, company, isActive
            })
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        this.fetchAdmins();
                    });
                } else {
                    this.error = true;
                }
            })
            .catch(
                error => {
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }


    @action updateUserPassword(id, password, role, successMsg, errorMsg) {
        this.loading = true;
        return fetch(config.BASE_URL + "user/password", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                id: id,
                password: password
            })
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        alert(successMsg)
                        if (role === "producer") {
                            this.fetchProducers()
                        }
                        if (role === "admin") {
                            this.fetchAdmins()
                        }
                    });
                } else {
                    alert(errorMsg)
                    this.error = true;
                }
            })
            .catch(
                error => {
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }

    @action updateCustomerPassword(id, password, successMsg, errorMsg) {
        this.loading = true;
        return fetch(config.BASE_URL + "user/password/customer", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                id: id,
                password: password
            })
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        alert(successMsg);
                    });
                } else {
                    alert(errorMsg);
                    this.error = true;
                }
            })
            .catch(
                error => {
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }

    @action initCustomerPassword(customerId) {
        this.loading = true;
        return fetch(config.BASE_URL + "user/customer/initpwd", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                customerid: customerId
            })
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {

                });
            } else {
                this.error = true;
            }
        })
            .catch(
                error => {
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }

    postLogoFile(file, tbSoftNr, producerNr) {
        const data = new FormData();
        data.append("image", file);
        this.fetching = true;
        return fetch(config.BASE_URL + "user/producer/logo", {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                "tbsoftnr": tbSoftNr,
                "producernr": producerNr
            },
            body: data
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {

                })
            } else {
                this.fetching = false;
            }
        }).catch(
            error => {
                this.fetching = false;
                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
    }
}

export default UserStore;