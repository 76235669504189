import { isDevelop } from "../js/helper/util";

export default {
    BASE_URL: isDevelop() ? "http://localhost:3010/api/" : "https://www.tbsoft.digital/api/",
    BASE_IMAGE_URL: isDevelop() ? "http://localhost:3010/images/" : "https://www.tbsoft.digital/images/",
    BASE_FILE_URL: isDevelop() ? "http://localhost:3010/" : "https://www.tbsoft.digital/",
    STORAGE_KEY: "loggedinuser",
    START_YEAR : 2021,
    CUSTOMER: "TBSoft Gmbh",
    LANGUAGES: "de,en,fr",
    ANIMALS: ["cow", "goat"]
}