import React from "react";
import {inject, observer} from "mobx-react";
import PropTypes from "prop-types";
import {Input, Button, Icon, Modal, Header} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import dayjs from "dayjs";

@inject("stores")
@withTranslation()
@observer
export default class UploadPdf extends React.Component {
    constructor(props) {
        super(props);
        this.deliveryStore = props.stores.deliveryStore;
        this.state = {
            selectedFile: "",
            deliveryNr: ""
         };
    }

    onFileUpload() {
        this.deliveryStore.postPdfFile(this.state.selectedFile, this.state.deliveryNr);
    }

    onFileChange(event) {
        this.setState({ selectedFile: event.target.files[0] });
    }

    deliveryNrChanged(e) {
        this.setState({deliveryNr: e.target.value})
    }
    render() {
        const {i18n} = this.props;
        return (
                <Modal
                    closeIcon
                    trigger={<Button>{ i18n.t( "page.upload") } </Button>}
                    className={"modal-top"}
                    open={this.state.isAddExcelModalOpen}
                    onOpen={() => this.setState({isAddExcelModalOpen: true})}
                    onClose={() => this.setState({isAddExcelModalOpen: false})}>
                    <Header content={ i18n.t( "option.addNewDocument") } />
                    <Modal.Content>
                        <Input onChange={this.deliveryNrChanged.bind(this)} />
                        <br/>
                        <Input style={{paddingTop: "20px"}} type="file" onChange={this.onFileChange.bind(this)} />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={() => this.setState({isAddExcelModalOpen: false})}>
                            <Icon name='remove' /> { i18n.t("page.cancel")}
                        </Button>
                        <Button disabled={false} color='green' onClick={this.onFileUpload.bind(this)}>
                            <Icon name='checkmark' /> { i18n.t("page.upload")}
                        </Button>
                    </Modal.Actions>
                </Modal>
        );
    }
}

UploadPdf.propTypes = {
};

UploadPdf.defaultPropTypes = {
};
