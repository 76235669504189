import React from "react";
import {Form, Segment, Message, Button} from "semantic-ui-react";
import {observable} from "mobx";
import {inject, observer} from "mobx-react";
import history from "../helper/browserHistory";
import {withTranslation} from "react-i18next";

@inject("stores")
@withTranslation()
@observer
export default class Login extends React.Component {

    @observable user = {
        username: '',
        password: ''
    };

    @observable loginError = {
        username: false,
        password: false,
        logIn: false
    };

    @observable loading = false;

    constructor(props) {
        super();
        this.user = {
            username: '',
            password: ''
        };

        this.userStore =  props.stores.userStore;
        this.appStore = props.stores.appStore;
        this.login = this.login.bind(this);
    }

    resetInputFields() {
        this.user = {
            username: '',
            password: ''
        }
    }

    login() {
        this.loading = true;
        this.loginError.logIn = false;

        if (this.user.username === "") {
            this.loading = false;
            this.loginError.username = true;
        }
        else if (this.user.password === "") {
            this.loading = false;
            this.loginError.password = true;
        }
        else {
            this.userStore.authenticateUser(this.user.username, this.user.password);
            this.loading = false;
        }

    }

    handleChange(prop, e) {
        this.loginError.username = false;
        this.loginError.password = false;
        this.user[prop] = e.target.value;
    }

    render() {
        return (
            <div>
                <Segment style={{display: "table", margin: "0 auto"}} compact loading={this.loading}>
                    <Form size="large" error={this.loginError.logIn}>
                        <Form.Field>
                            <Form.Input value={this.user.username}
                                        error={this.loginError.username}
                                        onChange={this.handleChange.bind(this, "username")}
                                        label='Username' type='Username'/>
                        </Form.Field>
                        <Form.Field>
                            <Form.Input value={this.user.password}
                                        error={this.loginError.password}
                                        onChange={this.handleChange.bind(this, "password")}
                                        label='Passwort' type='Password'/>
                        </Form.Field>
                        <Message
                            error
                            header='Login fehlgeschlagen'
                            content='Eingabe überprüfen und erneut versuchen.'
                        />
                        <Button onClick={this.login}>Log in</Button>
                    </Form>
                </Segment>
            </div>
        );
    }
}