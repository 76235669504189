import React from "react";
import {inject, observer} from "mobx-react";
import PropTypes from "prop-types";
import {Input, Button, Icon, Modal, Header, Form} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import dayjs from "dayjs";

@inject("stores")
@withTranslation()
@observer
export default class CsvDownload extends React.Component {
    constructor(props) {
        super(props);
        this.deliveryStore = props.stores.deliveryStore;
        this.userStore = props.stores.userStore;
        this.user = this.userStore.loadUser();
        this.state = {
            selectedFile: "",
            deliveryNr: "",
            radioCustomers: "all",
            startDate : dayjs().format("YYYY-MM-DD"),
            endDate : dayjs().format("YYYY-MM-DD"),
         };
    }

    onFileUpload() {
        this.deliveryStore.postPdfFile(this.state.selectedFile, this.state.deliveryNr);
    }

    onFileChange(event) {
        this.setState({ selectedFile: event.target.files[0] });
    }

    deliveryNrChanged(e) {
        this.setState({deliveryNr: e.target.value})
    }

    startDateChanged(a, { value }) {
        this.setState({ startDate: value});
    }

    endDateChanged(a, { value }) {
        this.setState({ endDate: value});
    }

    changeCustomers(a, { value }) {
        this.setState({radioCustomers: value})
    }

    downloadCsv() {
        let isAll = this.state.radioCustomers === "all";
        if (this.user.role === "customer") {
            isAll = false;
        }
        this.deliveryStore.downloadCsv(isAll, this.state.startDate, this.state.endDate);
    }

    render() {
        let customerNr = "";
        let numbersValid = true;
        let prodAdmin = false;
        if (this.user.role === "admin" || this.user.role === "producer") {
            prodAdmin = true;
            customerNr = this.deliveryStore.selectedCustomerNr;
            if ((this.deliveryStore.selectedProducerNr === -1) || (this.deliveryStore.selectedTbSoftNr === -1)) {
                numbersValid = false;
            }
        }



        const {i18n} = this.props;
        return (
                <Modal
                    closeIcon
                    trigger={<Button fluid>{ i18n.t( "csv.exportCsv") } </Button>}
                    className={"modal-top"}
                    open={this.state.isModalOpen}
                    onOpen={() => this.setState({isModalOpen: true})}
                    onClose={() => this.setState({isModalOpen: false})}>
                    <Header content={ i18n.t( "csv.exportCsv") } />
                    <Modal.Content>
                        <Form>
                            <Form.Group widths='equal'>
                                <Form.Input value={this.state.startDate} label={ i18n.t("csv.startDate")} type={"date"} onChange={this.startDateChanged.bind(this)} />
                                <Form.Input value={this.state.endDate} label={ i18n.t("csv.endDate")} type={"date"} onChange={this.endDateChanged.bind(this)} />
                            </Form.Group>
                            { prodAdmin === true && numbersValid === false ?
                                <p>{i18n.t("csv.chooseProducer")}</p> : null
                            }
                            { prodAdmin === true && numbersValid === true ? customerNr != -1 ?
                                <Form.Group inline>
                                    <Form.Radio
                                        label={ i18n.t("csv.allCustomers")}
                                        value='all'
                                        checked={this.state.radioCustomers === 'all'}
                                        onChange={this.changeCustomers.bind(this)}
                                    />
                                    <Form.Radio
                                        label={ i18n.t("csv.customer") + " " + customerNr}
                                        value='one'
                                        checked={this.state.radioCustomers === 'one'}
                                        onChange={this.changeCustomers.bind(this)}
                                    />
                                </Form.Group>
                                : <p>{ i18n.t("csv.noCustomerSelected")}</p>
                                : null }
                            <Form.Button disabled={numbersValid === false || this.startDate === "" || this.endDate === ""} color="green" onClick={this.downloadCsv.bind(this)}>{ i18n.t("csv.downloadCsv")}</Form.Button>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={() => this.setState({isModalOpen: false})}>
                            <Icon name='remove' /> { i18n.t("csv.close")}
                        </Button>
                    </Modal.Actions>
                </Modal>
        );
    }
}

CsvDownload.propTypes = {
};

CsvDownload.defaultPropTypes = {
};
