import React from "react";
import {inject, observer} from "mobx-react";
import PropTypes from "prop-types";
import {Input, Button, Icon, Modal, Header, Image, Grid} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import dayjs from "dayjs";
import config from "../../config/main.config";

@inject("stores")
@withTranslation()
@observer
export default class UploadLogo extends React.Component {
    constructor(props) {
        super(props);
        this.userStore = props.stores.userStore;
        this.tbSoftNr = props.tbSoftNr;
        this.producerNr = props.producerNr;
        this.state = {
            selectedFile: "",
         };
    }

    onFileUpload() {
        this.userStore.postLogoFile(this.state.selectedFile, this.tbSoftNr, this.producerNr);
        this.setState({isAddExcelModalOpen : false})
    }

    onFileChange(event) {
        this.setState({ selectedFile: event.target.files[0] });
    }

    render() {
        const {i18n} = this.props;
        return (
                <Modal
                    closeIcon
                    trigger={<Button size={"small"} >{ i18n.t( "user.logo") } </Button>}
                    className={"modal-top"}
                    open={this.state.isAddExcelModalOpen}
                    onOpen={() => this.setState({isAddExcelModalOpen: true})}
                    onClose={() => this.setState({isAddExcelModalOpen: false})}>
                    <Header content={ i18n.t( "option.addlogo") } />
                    <Modal.Content>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <Input style={{paddingTop: "20px"}} type="file" onChange={this.onFileChange.bind(this)} />
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Image src={config.BASE_IMAGE_URL + this.tbSoftNr+"_"+this.producerNr+".png"} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={() => this.setState({isAddExcelModalOpen: false})}>
                            <Icon name='remove' /> { i18n.t("page.cancel")}
                        </Button>
                        <Button disabled={false} color='green' onClick={this.onFileUpload.bind(this)}>
                            <Icon name='checkmark' /> { i18n.t("page.upload")}
                        </Button>
                    </Modal.Actions>
                </Modal>
        );
    }
}

UploadLogo.propTypes = {
    tbSoftNr: PropTypes.number,
    producerNr: PropTypes.number
};

UploadLogo.defaultPropTypes = {
};
