import React from "react";
import {inject, observer} from "mobx-react";
import PropTypes from "prop-types";
import {Input, Button, Icon, Modal, Header, TextArea, Grid, Form, Tab} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import dayjs from "dayjs";
import {assertInteger} from "pdf-lib";

@inject("stores")
@withTranslation()
@observer
export default class EmailTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.emailStore = props.stores.emailStore;
        this.tbSoftNr = props.tbSoftNr;
        this.producerNr = props.producerNr;
        this.subjectPwd = "";
        this.subjectDel = "";
        this.bodyPwd = "";
        this.bodyDel = "";
        this.state = {
            templateHtmlPwd : "",
            templateHtmlDel : "",
            subjectPwd: "",
            subjectDel: "",
            selectedFile: "",
            deliveryNr: "",
            type : "delivery"
         };
    }

    emailClicked() {
        this.setState({
            templateHtmlPwd : "",
            templateHtmlDel : "",
            subjectPwd: "",
            subjectDel: "",
            selectedFile: "",
            deliveryNr: "",
            type : "delivery"
        });
        this.emailStore.fetchEmail(this.tbSoftNr, this.producerNr, this.state.type);
    }

    saveEmail() {
        let subject = "";
        let body = "";
        let text = "";
        if (this.state.type === "password") {
            subject = this.emailStore.emailPwd.subject;
            body = this.emailStore.emailPwd.body;
            text = this.emailStore.emailPwd.text;
        }
        if (this.state.type === "delivery") {
            subject = this.emailStore.emailDel.subject;
            body = this.emailStore.emailDel.body;
            text = this.emailStore.emailDel.text;
        }
        const {i18n} = this.props;
        let success = i18n.t("email.success");
        let error =  i18n.t("email.error");
        this.emailStore.updateEmail(this.tbSoftNr, this.producerNr, this.state.type, subject, body, text, success, error);
    }

    onTabChanged(a, {activeIndex}) {
        let type = "";
        if (activeIndex === 0) {
            type = "delivery"
        }
        if (activeIndex === 1) {
            type = "password"
        }
        this.setState({ type: type })
    }

    subjectPwdChanged(e, {value}) {
        this.emailStore.updateEmailSubjectPwd(value);
        this.setState({});
    }

    subjectDelChanged(e, {value}) {
        this.emailStore.updateEmailSubjectDel(value);
        this.setState({});
    }

    bodyPwdChanged(e, {value}) {
        this.emailStore.updateEmailBodyPwd(value);
        this.setState({});
    }

    textPwdChanged(e, {value}) {
        this.emailStore.updateEmailTextPwd(value);
        this.setState({});
    }

    bodyDelChanged(e, {value}) {
        this.emailStore.updateEmailBodyDel(value);
        this.setState({});
    }

    textDelChanged(e, {value}) {
        this.emailStore.updateEmailTextDel(value);
        this.setState({});
    }

    render() {
        const {i18n} = this.props;
        let emailDel = this.emailStore.emailDel;
        let emailPwd = this.emailStore.emailPwd;

        let eMailTabs = [
           { menuItem: i18n.t("email.delivery"),
             render: () =>
                <Grid>
                    <Grid.Row style={{paddingTop: "30px"}}>
                        <Grid.Column>
                            <p style={{fontSize: "18px"}}>{i18n.t("email.subject")}</p>
                            <p>{i18n.t("email.descriptionSubjectDelivery")}</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={8}>
                            <Input fluid placeholder={i18n.t("email.subject")} value={ emailDel.subject }  onChange={ this.subjectDelChanged.bind(this)} />
                        </Grid.Column>
                        <Grid.Column width={8}>
                            { emailDel.subject }
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{paddingTop: 0}}>
                        <Grid.Column width={8}>
                            <p style={{fontSize: "20px"}}>{i18n.t("email.body")}</p>
                            <p>{i18n.t("email.descriptionBodyDelivery")}</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={8}>
                            <Form>
                                <TextArea rows={22} value={ emailDel.body } placeholder={i18n.t("email.enterHtmlCode")} onChange={ this.bodyDelChanged.bind(this) }/>
                            </Form>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <div dangerouslySetInnerHTML={{__html: emailDel.body}}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{paddingTop: 0}}>
                        <Grid.Column width={8}>
                            <p style={{fontSize: "20px"}}>{i18n.t("email.text")}</p>
                            <p>{i18n.t("email.descriptionBodyDelivery")}</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column width={8}>
                            <Form>
                                <TextArea rows={22}  value={ emailDel.text }  placeholder={i18n.t("email.enterText")} onChange={this.textDelChanged.bind(this)}/>
                            </Form>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <div>{ emailDel.text } </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            },
            {menuItem: i18n.t("email.credentials"),
            render: () =>
            <Grid>
                <Grid.Row style={{paddingTop: "30px"}}>
                    <Grid.Column>
                        <p style={{fontSize: "18px"}}>{i18n.t("email.subject")}</p>
                        <p>{i18n.t("email.descriptionSubjectPwd")}</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={8}>
                        <Input fluid placeholder={i18n.t("email.subject")} value={ emailPwd.subject } onChange={this.subjectPwdChanged.bind(this)}/>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        { emailPwd.subject }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{paddingTop: 0}}>
                    <Grid.Column width={8}>
                        <p style={{fontSize: "20px"}}>{i18n.t("email.body")}</p>
                        <p>{i18n.t("email.descriptionBodyPwd")}</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={8}>
                        <Form>
                            <TextArea rows={22}  value={ emailPwd.body }  placeholder={i18n.t("email.enterHtmlCode")} onChange={this.bodyPwdChanged.bind(this)}/>
                        </Form>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <div dangerouslySetInnerHTML={{__html: emailPwd.body }}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{paddingTop: 0}}>
                    <Grid.Column width={8}>
                        <p style={{fontSize: "20px"}}>{i18n.t("email.text")}</p>
                        <p>{i18n.t("email.descriptionBodyPwd")}</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={8}>
                        <Form>
                            <TextArea rows={22}  value={ emailPwd.text }  placeholder={i18n.t("email.enterText")} onChange={this.textPwdChanged.bind(this)}/>
                        </Form>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <div> { emailPwd.text } </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            }
        ]
        return (
                <Modal
                    closeIcon
                    trigger={<Button size={"small"} onClick={this.emailClicked.bind(this)}>{ i18n.t( "user.email") } </Button>}
                    className={"modal-top"}
                    open={this.state.isEmailTemplateOpen}
                    onOpen={() => this.setState({isEmailTemplateOpen: true})}
                    onClose={() => this.setState({isEmailTemplateOpen: false})}>
                    <Header content={ i18n.t( "email.newEmail") } />
                    <Modal.Content>
                        <Tab panes={eMailTabs} onTabChange={ this.onTabChanged.bind(this) }/>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='green' onClick={ this.saveEmail.bind(this)}>
                            <Icon name='checkmark' /> { i18n.t("page.save")}
                        </Button>
                        <Button color='red' onClick={() => this.setState({isEmailTemplateOpen: false})}>
                            <Icon name='remove' /> { i18n.t("page.cancel")}
                        </Button>
                    </Modal.Actions>
                </Modal>
        );
    }
}

EmailTemplate.propTypes = {
    tbSoftNr: PropTypes.number,
    producerNr: PropTypes.number
};

EmailTemplate.defaultPropTypes = {
};
