import {action, computed, observable} from 'mobx';
import config from "../../config/main.config";
import {
    getTokenFromLocalStorage,
} from "../helper/util";
import history from "../helper/browserHistory"

class EmailStore {
    @observable emails = [];
    @observable emailDel = {
        tbSoftNr: 0,
        producerNr: 0,
        type: "delivery",
        subject: "",
        body: "",
        text: ""
    };
    @observable emailPwd = {
        tbSoftNr: 0,
        producerNr: 0,
        type: "password",
        subject: "",
        body: "",
        text: ""
    };

    @observable loading = false;
    @observable error = false;

    @action fetchEmail(tbSoftNr, producerNr, type) {
        this.loading = true;
        return fetch(config.BASE_URL + "email/", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                "tbsoftnr": tbSoftNr,
                "producernr": producerNr,
                "type": type
            }
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        this.emails = json.emails;
                        let indexDel = this.emails.findIndex(em => em.type === "delivery");
                        if (indexDel !== -1) {
                            this.emailDel = this.emails[indexDel];
                        } else {
                            this.emailDel = {
                                tbSoftNr: 0,
                                producerNr: 0,
                                type: "delivery",
                                subject: "",
                                body: "",
                                text: ""
                            };
                        }
                        let indexPwd = this.emails.findIndex(em => em.type === "password");
                        if (indexPwd !== -1) {
                            this.emailPwd = this.emails[indexPwd];
                        }
                        else {
                            this.emailPwd =  {
                                tbSoftNr: 0,
                                producerNr: 0,
                                type: "password",
                                subject: "",
                                body: "",
                                text: ""
                            };
                        }
                    });
                } else {
                    this.error = true;
                    this.customers = [];
                }
            })
            .catch(
                error => {
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }

    @action updateEmailSubjectPwd(subject) {
        this.emailPwd.subject = subject;
    }

    @action updateEmailSubjectDel(subject) {
        this.emailDel.subject = subject;
    }

    @action updateEmailBodyPwd(body) {
        this.emailPwd.body = body;
    }

    @action updateEmailBodyDel(body) {
        this.emailDel.body = body;
    }

    @action updateEmailTextDel(text) {
        this.emailDel.text = text;
    }

    @action updateEmailTextPwd(text) {
        this.emailPwd.text = text;
    }

    @action updateEmail(tbSoftNr, producerNr, type, subject, body, text, success, error) {
        this.loading = true;
        console.log(tbSoftNr, producerNr, type, subject, body);
        return fetch(config.BASE_URL + "email/", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                tbSoftNr, producerNr, type, subject, body, text
            })
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        if (json.success === "err") {
                            alert(error)
                        }
                        if (json.success === "ok") {
                            alert(success)
                        }
                    });
                } else {
                    this.error = true;
                }
            })
            .catch(
                error => {
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }
}

export default EmailStore;