import React from "react";
import {inject, observer} from "mobx-react";
import PropTypes from "prop-types";
import {Input, Button, Icon, TableHeader, Table, Grid, Checkbox} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import TextEdit from "./TextEdit";

@inject("stores")
@withTranslation()
@observer
export default class CustomerSelector extends React.Component {
    constructor(props) {
        super(props);
        this.userStore = props.stores.userStore;
        this.deliveryStore = props.stores.deliveryStore;
        this.state = {
            searchCustomer: '',
            selectedCustomerId: ''
        };
    }

    componentDidMount() {
    }

    customerSelectedClicked(user) {
        this.setState({selectedCustomerId : user})
        this.deliveryStore.setSelectedCustomerNr(user.customerNr);
        this.deliveryStore.fetchDelivery();
    }

    searchCustomerChanged(e) {
        this.setState({searchCustomer : e.target.value})
    }

    render() {
        const {i18n} = this.props;
        let { customers } = this.userStore;
        let customerTable = [];
        if (customers.length > 0) {
            for (let customer of customers) {
                if ((customer.customerName.toLowerCase().search(this.state.searchCustomer.toLowerCase()) !== -1)
                    || (customer.customerZip.toLowerCase().search(this.state.searchCustomer.toLowerCase()) !== -1)
                    || (customer.customerNr.toString().search(this.state.searchCustomer.toLowerCase()) !== -1)
                    || (customer.customerCity.toLowerCase().search(this.state.searchCustomer.toLowerCase()) !== -1)) {
                    customerTable.push(
                        <Table.Row key={"customer_customer_" + customer.id}  onClick={this.customerSelectedClicked.bind(this, customer)} positive = {this.state.selectedCustomerId.id === customer.id}>
                            <Table.Cell>{customer.customerNr}</Table.Cell>
                            <Table.Cell>{customer.customerName}</Table.Cell>
                            <Table.Cell>{customer.customerZip}</Table.Cell>
                            <Table.Cell>{customer.customerCity}</Table.Cell>
                        </Table.Row>)
                }
            }
        }

        return (
            <div style={{overflowX: "auto", minHeight: "500px"}}>
                <Input fluid placeholder={i18n.t("customer.searchCustomer")} onChange={this.searchCustomerChanged.bind(this)}></Input>
                <Table celled striped compact>
                    <TableHeader>
                        <Table.Row>
                            <Table.HeaderCell>{ i18n.t("user.customerNrShort")}</Table.HeaderCell>
                            <Table.HeaderCell>{ i18n.t("user.company")}</Table.HeaderCell>
                            <Table.HeaderCell>{ i18n.t("user.zip")}</Table.HeaderCell>
                            <Table.HeaderCell>{ i18n.t("user.city")}</Table.HeaderCell>
                        </Table.Row>
                    </TableHeader>
                    <Table.Body>
                        { customerTable }
                    </Table.Body>
                </Table>
            </div>
        );
    }
}
