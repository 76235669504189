import React from "react";
import {
    Button,
    Header,
    Form,
    Grid,
    Modal,
    Table,
    TableHeader,
    Input,
    Dropdown
} from "semantic-ui-react";

import {inject, observer} from "mobx-react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {withTranslation} from "react-i18next";
import { passwordStrength } from 'check-password-strength'

@inject("stores")
@withTranslation()
@observer
export default class CustomerPage extends React.Component {
    constructor(props) {
        super(props);
        this.userStore = props.stores.userStore;
        this.user = this.userStore.loadUser();
        let producer = {};
        if (this.user.role === "producer") {
            producer.producerNr = this.user.producerNr;
            producer.tbSoftNr = this.user.tbSoftNr;
        }
        this.state = {
            searchInternal: "",
            selectedProducer: producer,
            addMode: "",
            showAdmin: false,
            pwdUser: {},
            isPwdModalOpen: false,
            newPassword: "",
            isInitPwdModalOpen: false
        };
    }

    componentDidMount() {
        if (this.user.role === "admin") {
            this.userStore.fetchProducers();
        }

        if (this.user.role === "producer") {
            this.userStore.fetchCustomers(this.state.selectedProducer.tbSoftNr, this.state.selectedProducer.producerNr);
        }
    }

    searchInternalChanged(e) {
        this.setState({searchInternal: e.target.value});
    }

    addPrenameChanged(e) {
        this.generateUserName(e.target.value, this.state.addSurname);
        this.setState({addPrename : e.target.value});
    }

    addSurnameChanged(e) {
        this.generateUserName(this.state.addPrename, e.target.value);
        this.setState({addSurname : e.target.value});
    }

    addUsernameChanged(e) {
        this.generateUserName(e.target.value, "");
    }

    addRoleFarmerChanged(e, {checked}) {
        this.setState({addRoleFarmer : checked})
    }

    addRoleAdminChanged(e, {checked}) {
        this.setState({addRoleAdmin : checked})
    }

    checkUsername(username) {
        let { users } = this.userStore;
        let isValid = users.find(user => user.username === username) === undefined && username.length >= 2
        return isValid;
    }

/*    activeChanged(user,  e, {checked}) {
        user.isActive = checked ? 1 : 0;
        this.updateCustomer(user);
    }
*/

    producerSelected(e, {value}) {
        let producer = this.userStore.producers.find(prod => prod.id === value);
        this.userStore.fetchCustomers(producer.tbSoftNr, producer.producerNr);
        this.setState({ selectedProducer: value });
    }


    newPasswordChanged(a,b) {
        this.setState({newPassword: b.value});
    }

    newPasswordClicked(user) {
        this.setState({isPwdModalOpen:true, pwdUser: user  })
    }

    savePassword() {
        const {i18n} = this.props;
        if (passwordStrength(this.state.newPassword).id === 0) {
            alert(i18n.t("password.tooWeak"))
        } else if (this.state.newPassword.length <= 8) {
            alert(i18n.t("password.tooSmall"))
        } else {
            let successMsg = i18n.t("customer.passwordSuccess");
            let errorMsg = i18n.t("customer.passwordError");
            this.userStore.updateCustomerPassword(this.state.pwdUser.id,this.state.newPassword, successMsg, errorMsg)
            this.setState({newPassword: "", isPwdModalOpen: false});
        }

    }

    setOpenInitPwdModal(user) {
        this.setState({isInitPwdModalOpen:true, pwdUser: user  })
    }

    initCustomerPassword() {
        this.userStore.initCustomerPassword(this.state.pwdUser.id);
        this.setState({ isInitPwdModalOpen:false  })
    }

    render() {
        let producerOptions = [];
        if (this.userStore.producers.length > 0) {
            producerOptions = this.userStore.producers.map( producer =>
                { return (
                    { key: producer.id,
                      text: producer.tbSoftNr + " | " + producer.producerNr + ": " + producer.companyName + " | " + producer.prename + " " + producer.surname,
                      value: producer.id }
                      )
                });
         }

        const {i18n} = this.props;
        let activeUserTable = [];
        let customers = this.userStore.customers;
        console.log("customers")
        console.log(customers)
        if (customers.length > 0) {
            for (let customer of customers) {
                if (customer.customerName.toLowerCase().search(this.state.searchInternal.toLowerCase()) !== -1
                    || (customer.customerAdditionalName.toLowerCase().search(this.state.searchInternal.toLowerCase()) !== -1)
                    || (customer.customerNr.toString().search(this.state.searchInternal.toLowerCase()) !== -1)
                    || (customer.customerStreet.toLowerCase().search(this.state.searchInternal.toLowerCase()) !== -1)
                    || (customer.customerZip.toLowerCase().search(this.state.searchInternal.toLowerCase()) !== -1)
                    || (customer.customerCity.toLowerCase().search(this.state.searchInternal.toLowerCase()) !== -1)
                    || (customer.latestEmailFromDelivery.toLowerCase().search(this.state.searchInternal.toLowerCase()) !== -1)
                    || (customer.portalEmail.toLowerCase().search(this.state.searchInternal.toLowerCase()) !== -1)) {
                    activeUserTable.push(
                        <Table.Row key={"user_active_" + customer.id}>
                            <Table.Cell>{customer.customerName}</Table.Cell>
                            <Table.Cell>{customer.customerAdditionalName}</Table.Cell>
                            <Table.Cell>{customer.customerNr}</Table.Cell>
                            <Table.Cell>{customer.customerStreet}</Table.Cell>
                            <Table.Cell>{customer.customerZip + " " + customer.customerCity}</Table.Cell>
                            <Table.Cell>{customer.latestEmailFromDelivery}</Table.Cell>
                            <Table.Cell>{customer.portalEmail}</Table.Cell>
                            <Table.Cell>{customer.passwordInitialized === 0 ? i18n.t("customer.pwdNotInitialized") : i18n.t("customer.pwdInitialized")}</Table.Cell>
                            <Table.Cell><Button size={"small"}
                                                onClick={this.setOpenInitPwdModal.bind(this, customer)}>{i18n.t("customer.newPwd")}</Button></Table.Cell>
                            {/*}  <Table.Cell><Checkbox label={i18n.t("customer.active")} checked={ customer.isActive === 1 } onChange={this.activeChanged.bind(this, customer)}/> </Table.Cell> */}
                            <Table.Cell><Button size="small"
                                                onClick={this.newPasswordClicked.bind(this, customer)}>{i18n.t("customer.setPwd")}</Button></Table.Cell>
                        </Table.Row>)
                }
            }
        }



        return (
            <div>
                <Grid>
                    { this.user.role === "admin" ?
                        <Grid.Row columns={2}>
                            <Grid.Column width={8}>
                                <Form>
                                    <Form.Field>
                                    <label>{i18n.t("user.chooseProducer")}</label>
                                    <Dropdown
                                        placeholder={i18n.t("user.searchProducer")}
                                        fluid
                                        labeled
                                        icon='dropdown'
                                        search
                                        selection
                                        options={producerOptions}
                                        onChange={ this.producerSelected.bind(this) }
                                        value={this.state.selectedProducer}
                                    />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                        </Grid.Row> : null }
                </Grid>
                { this.state.selectedProducer !== "" ?
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <h2>{i18n.t("user.customers")}</h2>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Input fluid placeholder={ i18n.t("user.filter") + "..."} onChange={this.searchInternalChanged.bind(this)}/>
                            <h3>{i18n.t("user.activeUser")}</h3>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <div style={{overflowX: "auto", minHeight: "500px"}}>
                            <Table celled striped>
                                <TableHeader>
                                    <Table.Row>
                                        <Table.HeaderCell>{ i18n.t("customer.customerName")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("customer.customerAdditionalName")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("customer.customerNr")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("customer.customerStreet")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("customer.zipcity")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("customer.latestEmail")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("customer.portalEmail")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("customer.portalInit")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("customer.initPassword")}</Table.HeaderCell>
                                        <Table.HeaderCell>{ i18n.t("customer.setPassword")}</Table.HeaderCell>
                                    </Table.Row>
                                </TableHeader>
                                <Table.Body>
                                    {   activeUserTable  }
                                </Table.Body>
                            </Table>
                            </div>
                        </Grid.Column>
                     </Grid.Row>
                </Grid> : null}
                <Modal
                    closeIcon
                    className={"modal-top"}
                    open={this.state.isPwdModalOpen}
                    onClose={() => this.setState({isPwdModalOpen: false})}
                    onOpen={() => setOpen(true)}>
                    <Header icon='user' content={ i18n.t( "user.newPassword") } />
                    <Modal.Content>
                        <Form>
                            <Form.Input width={8} label={i18n.t("user.newPassword")} onChange={this.newPasswordChanged.bind(this) } placeholder={i18n.t("user.newPassword")}/>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={() => this.setState({isPwdModalOpen: false})}>
                            <Icon name='remove' /> { i18n.t("user.cancel")}
                        </Button>
                        <Button disabled={false } color='green' onClick={this.savePassword.bind(this)}>
                            <Icon name='checkmark' /> { i18n.t("user.save")}
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal  open={this.state.isInitPwdModalOpen}>
                    <Modal.Header> { i18n.t("password.initPassword") + " für Kundennummer: " + this.state.pwdUser.customerNr}</Modal.Header>
                    <Modal.Content>
                        { i18n.t("password.reallyInitPwd") }
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={() => { this.setState(isInitPwdModalOpen: false)}}>
                             {i18n.t("customer.cancel")}
                        </Button>
                        <Button positive onClick={ this.initCustomerPassword.bind(this)}>
                             {i18n.t("customer.setInitPwd")}
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}