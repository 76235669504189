import React from "react";
import {inject, observer} from "mobx-react";
import PropTypes from "prop-types";
import {
    Input,
    Button,
    Icon,
    Table,
    TableHeader,
    Grid,
    Pagination,
    Dropdown,
    Checkbox,
    Modal,
    Header, TableRow
} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import dayjs from "dayjs";
import {toJS} from "mobx";
import CustomerSelector from "./CustomerSelector";
import CsvDownload from "./CsvDownload";

@inject("stores")
@withTranslation()
@observer
export default class DeliveryList extends React.Component {
    constructor(props) {
        super(props);
        this.deliveryStore = props.stores.deliveryStore;
        this.userStore = props.stores.userStore;
        this.user = this.userStore.loadUser();
        this.state = {
            activePage: 1,
            noItems: 25,
            searchDelivery: '',
            isDeliveryModalOpen: false
        };

        this.tbSoftNr = props.tbSoftNr;
        this.producerNr = props.producerNr;
        this.customerNr = props.customerNr;

        const deliveryPerPage = props.i18n.t("delivery.deliveries");
        this.noItemsOptions = [
            {
                key: 0,
                text: "10 " + deliveryPerPage,
                value: 10
            },
            {
                key: 1,
                text: "25 " + deliveryPerPage,
                value: 25
            },
            {
                key: 2,
                text: "50 " + deliveryPerPage,
                value: 50
            },
            {
                key: 3,
                text: "100 " + deliveryPerPage,
                value: 100
            }];
        this.selectedDelivery = {
            deliveryNoteNr : "",
            arrivalTime : "",
            departureTime : "",
            waitingMin : "",
            isWaiting : "",
            waitingReason : "",
            tbsoftCustomerNr : "",
            producerNr : "",
            date: "",
            customerNr: "",
            customerName: "",
            customerAdditionalName: "",
            customerStreet: "",
            customerZip: "",
            customerCity: "",
            customerEmail: "",
            factoryNr: "",
            factoryName: "",
            factoryAdditionalName: "",
            factoryStreet: "",
            factoryZip: "",
            factoryCity: "",
            constructionNr: "",
            constructionName: "",
            constructionAdditionalName: "",
            constructionStreet: "",
            constructionZip: "",
            constructionCity: "",
        };
    }

    componentDidMount () {
        this.userStore.loadUser();
    }

    deliveryClicked() {

    }

    downloadPdf(delivery) {
        this.deliveryStore.fetchPdf(delivery.deliveryId);
    }

    paginationChange(a, {activePage}) {
        this.setState({activePage: activePage});
    }

    noItemsChanged(e, {value}) {
        this.setState( { noItems: value} );
    }

    searchDeliveryChanged(e) {
        this.setState({searchDelivery: e.target.value});
    }

    downloadChanged(delivery, a ,{ checked }) {
        this.deliveryStore.setChecked(delivery.id, checked);
        this.setState({});
    }

    selectAllNew() {
        let user = this.userStore.loadUser();
        let role = user.role;
        let deliveries = this.deliveryStore.delivery;
        for (let delivery of deliveries) {
            if (role === "producer") {
                if (delivery.updatedByPwa === 1 && delivery.downloadedByProducer === 0) {
                    this.deliveryStore.setChecked(delivery.id, true);
                }
            }
            if (role === "customer") {
                if (delivery.updatedByPwa === 1  && delivery.downloadedByCustomer === 0 ) {
                    this.deliveryStore.setChecked(delivery.id, true);
                }
            }
        }
        this.setState({});
    }

    downloadAllFiles() {
        let downloadDeliveries = this.deliveryStore.delivery.filter(del => del.isChecked === true).map(del => del.id);
        // selected deliveries
        this.deliveryStore.downloadMultipleFiles(downloadDeliveries);
    }

    showDeliveryModalOpen(delivery) {
        this.selectedDelivery = delivery;
        this.setState({ isDeliveryModalOpen: true})
    }

    render() {
        const {i18n} = this.props;
        let deliveryTable = [];
        let deliveries = toJS(this.deliveryStore.delivery);
        let noPages = 1;
        let role = this.user.role;
        if (deliveries !== undefined) {
            let searchedDeliveries = [];
            for (let del of deliveries) {
                if (   (del.deliveryNoteNr.toString().search(this.state.searchDelivery.toLowerCase()) !== -1)
                    || (del.constructionNr.toString().search(this.state.searchDelivery.toLowerCase()) !== -1)
                    || (del.constructionName.toLowerCase().search(this.state.searchDelivery.toLowerCase()) !== -1)
                    || (del.constructionAdditionalName.toLowerCase().search(this.state.searchDelivery.toLowerCase()) !== -1)
                    || (del.constructionStreet.toLowerCase().search(this.state.searchDelivery.toLowerCase()) !== -1)
                    || (del.constructionCity.toLowerCase().search(this.state.searchDelivery.toLowerCase()) !== -1)
                    || (del.materialName.toLowerCase().search(this.state.searchDelivery.toLowerCase()) !== -1)
                    || (del.materialAmount.toString().toString().search(this.state.searchDelivery.toLowerCase()) !== -1))
                    searchedDeliveries.push(del);
            }
            let deliveriesPerPage = this.state.noItems;
            noPages = Math.ceil(searchedDeliveries.length / deliveriesPerPage);
            let filteredDeliveries = searchedDeliveries.slice((this.state.activePage-1)*deliveriesPerPage,((this.state.activePage)*deliveriesPerPage)-1);
            if (deliveries.length > 0) {
                for (let delivery of filteredDeliveries) {
                    let fontStyle = {};
                    if (role === "customer") {
                        if (delivery.downloadedByCustomer === 0 && delivery.updatedByPwa === 1) {
                            fontStyle = {fontWeight: "600"}
                        }
                    }
                    if (role === "producer") {
                        if (delivery.downloadedByProducer === 0 && delivery.updatedByPwa === 1) {
                            fontStyle = {fontWeight: "600"}
                        }
                    }
                    deliveryTable.push(
                        <Table.Row warning={!delivery.updatedByPwa} key={"user_customer_" + delivery.id}
                                   onClick={this.deliveryClicked.bind(this, delivery)} style={ fontStyle } >
                            <Table.Cell onClick={this.showDeliveryModalOpen.bind(this, delivery)}><Icon name="info"/></Table.Cell>
                            <Table.Cell>{delivery.updatedByPwa ? <Checkbox checked={delivery.isChecked} onChange={this.downloadChanged.bind(this, delivery)}/> : null}</Table.Cell>
                            <Table.Cell onClick={this.showDeliveryModalOpen.bind(this, delivery)}>{delivery.deliveryNoteNr}</Table.Cell>
                            <Table.Cell onClick={this.showDeliveryModalOpen.bind(this, delivery)}>{dayjs(delivery.date).format("DD.MM.YYYY")}</Table.Cell>
                            <Table.Cell onClick={this.showDeliveryModalOpen.bind(this, delivery)}>{delivery.constructionNr}</Table.Cell>
                            <Table.Cell onClick={this.showDeliveryModalOpen.bind(this, delivery)}>{delivery.constructionName}</Table.Cell>
                            <Table.Cell onClick={this.showDeliveryModalOpen.bind(this, delivery)}>{delivery.constructionAdditionalName}</Table.Cell>
                            <Table.Cell onClick={this.showDeliveryModalOpen.bind(this, delivery)}>{delivery.constructionStreet}</Table.Cell>
                            <Table.Cell onClick={this.showDeliveryModalOpen.bind(this, delivery)}>{delivery.constructionZip + " " + delivery.constructionCity}</Table.Cell>
                            <Table.Cell onClick={this.showDeliveryModalOpen.bind(this, delivery)}>{delivery.materialName}</Table.Cell>
                            <Table.Cell onClick={this.showDeliveryModalOpen.bind(this, delivery)}>{delivery.materialAmount + " " + delivery.materialUnit}</Table.Cell>
                            <Table.Cell>{delivery.updatedByPwa ? <Button size={"small"} onClick={this.downloadPdf.bind(this, delivery)}><Icon
                                name={"download"}/></Button> : <div style={{fontSize: "12px"}}>{i18n.t("delivery.noPwaData")}</div>}</Table.Cell>
                        </Table.Row>
                    )
                }

            }
        }

        return (
            <div>
               <Grid>
                    <Grid.Row>
                        { (this.user.role === "producer") || (this.user.role === "admin") ?
                                <Grid.Column width={4} style={{ paddingTop: "52px"}}>
                                    <CustomerSelector />
                                </Grid.Column>
                             : null }
                        <Grid.Column width={(this.user.role === "producer") || (this.user.role === "admin")  ? 12 : 16}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={9}>
                                        <Pagination
                                            onPageChange={this.paginationChange.bind(this)}
                                            defaultActivePage={1}
                                            totalPages={ noPages } />
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Dropdown fluid selection direction='right' color='black'
                                                  options={ this.noItemsOptions }
                                                  value={ this.state.noItems }
                                                  onChange={this.noItemsChanged.bind(this)}/>
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                        <CsvDownload tbSoftNr={ this.tbSoftNr } producerNr={this.producerNr} customerNr={this.customerNr}/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <div style={{overflowX: "auto", minHeight: "500px", paddingTop: "10px"}}>
                                <Input fluid placeholder={i18n.t("delivery.searchDelivery")} onChange={this.searchDeliveryChanged.bind(this)} />
                                <Table celled striped compact>
                                    <TableHeader>
                                        <Table.Row>
                                            <Table.HeaderCell rowSpan={2}><Icon name="info"/></Table.HeaderCell>
                                            <Table.HeaderCell rowSpan={1}><Button fluid size="tiny" onClick={this.selectAllNew.bind(this)} icon={"checkmark box"}/></Table.HeaderCell>
                                            <Table.HeaderCell rowSpan={2}>{ i18n.t("delivery.deliveryId")}</Table.HeaderCell>
                                            <Table.HeaderCell rowSpan={2}>{ i18n.t("delivery.date")}</Table.HeaderCell>
                                            <Table.HeaderCell colSpan={5}>{ i18n.t("delivery.construction")}</Table.HeaderCell>
                                            <Table.HeaderCell colSpan={2}>{ i18n.t("delivery.material")}</Table.HeaderCell>
                                            <Table.HeaderCell rowSpan={2}>{ i18n.t("delivery.deliveryPdf")}</Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell><Button disabled={this.deliveryStore.delivery.findIndex(del => del.isChecked === true) === -1} fluid size="tiny" onClick={this.downloadAllFiles.bind(this)} icon={"download"}/></Table.HeaderCell>
                                            <Table.HeaderCell>{ i18n.t("delivery.constructionNr")}</Table.HeaderCell>
                                            <Table.HeaderCell>{ i18n.t("delivery.constructionName")}</Table.HeaderCell>
                                            <Table.HeaderCell>{ i18n.t("delivery.constructionAddName")}</Table.HeaderCell>
                                            <Table.HeaderCell>{ i18n.t("delivery.constructionStreet")}</Table.HeaderCell>
                                            <Table.HeaderCell>{ i18n.t("delivery.constructionCity")}</Table.HeaderCell>
                                            <Table.HeaderCell>{ i18n.t("delivery.materialName")}</Table.HeaderCell>
                                            <Table.HeaderCell>{ i18n.t("delivery.materialAmount")}</Table.HeaderCell>
                                        </Table.Row>
                                    </TableHeader>
                                    <Table.Body>
                                        { deliveryTable }
                                    </Table.Body>
                                </Table>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
               </Grid>
                <Modal open={this.state.isDeliveryModalOpen } centered={false} style={{ position: 'static' }} >
                    <Modal.Header>{ i18n.t("delivery.detail") + " " + this.selectedDelivery.deliveryNoteNr }</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Table>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>{i18n.t("delivery.deliveryNr")}</Table.Cell>
                                        <Table.Cell><b>{ this.selectedDelivery.deliveryId}</b></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{i18n.t("delivery.tbsoftCustomerNr")}</Table.Cell>
                                        <Table.Cell><b>{ this.selectedDelivery.tbsoftCustomerNr}</b></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{i18n.t("delivery.producerNr")}</Table.Cell>
                                        <Table.Cell><b>{ this.selectedDelivery.producerNr}</b></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{i18n.t("delivery.customerNr")}</Table.Cell>
                                        <Table.Cell><b>{ this.selectedDelivery.customerNr}</b></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{i18n.t("delivery.deliveryNoteNr")}</Table.Cell>
                                        <Table.Cell><b>{ this.selectedDelivery.deliveryNoteNr}</b></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{i18n.t("delivery.date")}</Table.Cell>
                                        <Table.Cell><b>{  dayjs(this.selectedDelivery.date).format("DD.MM.YYYY")  }</b></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{i18n.t("delivery.arrivalTime")}</Table.Cell>
                                        <Table.Cell><b>{ dayjs(this.selectedDelivery.arrivalTime).format("HH:mm")}</b></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{i18n.t("delivery.departureTime")}</Table.Cell>
                                        <Table.Cell><b>{ dayjs(this.selectedDelivery.departureTime).format("HH:mm") }</b></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{i18n.t("delivery.waitingRegistered")}</Table.Cell>
                                        <Table.Cell><b>{ this.selectedDelivery.isWaiting === 0 ? i18n.t("delivery.noWaiting") : i18n.t("delivery.waiting")}</b></Table.Cell>
                                    </Table.Row>
                                    { this.selectedDelivery.isWaiting === 1 ?
                                        <Table.Row>
                                            <Table.Cell>{i18n.t("delivery.waitingMin")}</Table.Cell>
                                            <Table.Cell><b>{ this.selectedDelivery.waitingMin + " Min"} </b></Table.Cell>
                                        </Table.Row> : null }
                                    { this.selectedDelivery.isWaiting === 1 ?
                                        <Table.Row>
                                            <Table.Cell>{i18n.t("delivery.waitingReason")}</Table.Cell>
                                            <Table.Cell><b>{ this.selectedDelivery.waitingReason}</b></Table.Cell>
                                        </Table.Row> : null }
                                    <Table.Row>
                                        <Table.Cell>{i18n.t("delivery.aCustomer")}</Table.Cell>
                                        <Table.Cell>
                                            <b>
                                                { this.selectedDelivery.customerName + " " + this.selectedDelivery.customerAdditionalName}
                                                <br/>
                                                { this.selectedDelivery.customerStreet}
                                                <br/>
                                                { this.selectedDelivery.customerZip + " " + this.selectedDelivery.customerCity}
                                                <br/>
                                                { this.selectedDelivery.customerEmail}
                                            </b>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{i18n.t("delivery.factory")}</Table.Cell>
                                        <Table.Cell>
                                            <b>
                                                { i18n.t("delivery.factoryNr") + " " + this.selectedDelivery.factoryNr}
                                                <br/>
                                                { this.selectedDelivery.factoryName + " " + this.selectedDelivery.factoryAdditionalName}
                                                <br/>
                                                { this.selectedDelivery.factoryStreet}
                                                <br/>
                                                { this.selectedDelivery.factoryZip + " " + this.selectedDelivery.factoryCity}
                                            </b>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{i18n.t("delivery.construction")}</Table.Cell>
                                        <Table.Cell>
                                            <b>
                                                { i18n.t("delivery.constructionNrLong") + " " + this.selectedDelivery.constructionNr}
                                                <br/>
                                                { this.selectedDelivery.constructionName + " " + this.selectedDelivery.constructionAdditionalName}
                                                <br/>
                                                { this.selectedDelivery.constructionStreet}
                                                <br/>
                                                { this.selectedDelivery.constructionZip + " " + this.selectedDelivery.constructionCity }
                                            </b>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>{i18n.t("delivery.material")}</Table.Cell>
                                        <Table.Cell>
                                            <b>
                                                { this.selectedDelivery.materialName }
                                                <br/>
                                                { this.selectedDelivery.materialAmount + " " + this.selectedDelivery.materialUnit }
                                            </b>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => {this.setState({isDeliveryModalOpen: false})}} positive>{i18n.t("page.close")}</Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

DeliveryList.propTypes = {
};

DeliveryList.defaultPropTypes = {
};
