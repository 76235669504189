import DeliveryStore from './deliveryStore'
import UserStore from './userStore'
import ScaleStore from './scaleStore'
import EmailStore from './emailStore'

/**
 Inject Inital State into Stores
 For now only text store is implemented because it is the only one we need in SSR
 If you need another store implement it like text store
 */
export default (stores) => ({
    deliveryStore: new DeliveryStore(stores),
    userStore: new UserStore(stores),
    scaleStore: new ScaleStore(stores),
    emailStore: new EmailStore(stores)
});